import Modals from '../Modals';
import { useAppContext } from 'components/Context/AppContext';
import { ReactComponent as LocationFilledIcon } from 'icons/location-filled-icon.svg';
import { Button, InputField, SelectFieldSearch } from 'components/Global';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createRentalLocation, updateRentalLocation } from 'features/rental-location/actions';
import timeZones from '../../../utils/time-zones.json';
import { checkEmptyObject, checkPermission, mappingLongTimeZones } from 'utils/functionality';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Tabs, { TabPane } from 'components/Global/Tabs';
import ServicesForm from './ServicesForm';
import timeZonesCode from '../../../utils/timezone-code.json';

const AddCityModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // All Time Zone
  const allTimeZone = mappingLongTimeZones(timeZones).map((item, idx) => ({ id: idx + 1, name: item }));

  // Context
  const { setShowAddNewCity, showToast, setShowSpinner } = useAppContext();
  const { data: serviceData } = useSelector((state) => state.services);
  const { selected: selectedLocation } = useSelector((state) => state.rentalLocation);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);
  const { data: dataCurrencies } = useSelector((state) => state.currency);

  const [searchParams] = useSearchParams();
  const locationId = searchParams.get('location_id');

  const [allServices, setAllServices] = useState([]);
  const [mappedCurrencies, setMappedCurrencies] = useState([]);
  const [activeTab, setActiveTab] = useState('With Driver');

  // state of city name
  const [cityName, setCityName] = useState('');
  const [timeZoneIdentifier, setTimeZoneIdentifier] = useState(0);
  const [services, setServices] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(0);
  const [isSpecialAirportTransfer, setIsSpecialAirportTransfer] = useState(false);

  const getTimezoneCode = (friendlyName) => {
    if (!friendlyName || !timeZonesCode) return;

    const region = friendlyName?.split('/')[0];
    const timeZoneCode = timeZonesCode.timezones[`${region}`][`${friendlyName}`];

    return timeZoneCode;
  };

  // show and hide modal
  const stateModalHandler = (state) => {
    setShowAddNewCity(state);
    navigate('/rental-area');
  };

  // function mutation
  const actionMutation = async (method, payload, successMessage, errorMessage) => {
    if (method === 'ADD') {
      try {
        setShowSpinner(true);
        await dispatch(createRentalLocation(payload)).unwrap();
        showToast({ type: 'success', message: successMessage });
        setShowAddNewCity(false);
        setShowSpinner(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        showToast({ type: 'error', message: error.message ? error.message : errorMessage });
        setShowSpinner(false);
      }
    } else {
      try {
        setShowSpinner(true);
        await dispatch(updateRentalLocation({ id: payload.id, payload: payload.body })).unwrap();
        showToast({ type: 'success', message: successMessage });
        setShowAddNewCity(false);
        setShowSpinner(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        showToast({ type: 'error', message: error.message ? error.message : errorMessage });
        setShowSpinner(false);
      }
    }
  };

  // submit add function
  const submitHandler = async (e) => {
    e.preventDefault();

    if (!cityName) {
      showToast({ type: 'error', message: 'Masukan Nama Daerah Sewa' });
      return;
    }

    if (!timeZoneIdentifier) {
      showToast({ type: 'error', message: 'Pilih Time Zone Identifier' });
      return;
    }

    if (!selectedCurrency) {
      showToast({ type: 'error', message: 'Pilih Currency' });
      return;
    }

    if (!services.length) {
      showToast({ type: 'error', message: 'Pilih Service' });
      return;
    }

    const payload = {
      name: cityName,
      time_zone_identifier: allTimeZone.find((item) => item.id == timeZoneIdentifier)?.name,
      sub_service_locations: services,
      currency: mappedCurrencies.find((item) => item.id == selectedCurrency)?.name,
      is_special_airport_transfer: isSpecialAirportTransfer,
      time_zone: getTimezoneCode(allTimeZone.find((item) => item.id == timeZoneIdentifier)?.name),
    };

    if (locationId) {
      actionMutation('UPDATE', { id: locationId, body: payload }, 'Berhasil Update Item', 'Gagal Update Item');
    } else {
      actionMutation('ADD', payload, 'Berhasil Tambah Item', 'Gagal Tambah Item');
    }
  };

  // Mapping data if selected data doesnt empty
  useEffect(() => {
    let mappingServiceData = [];

    if (Object.keys(selectedLocation).length && locationId && serviceData.length && !checkEmptyObject(dataCurrencies)) {
      // set time zone identifier
      const selectedTimeZoneIdentifier = allTimeZone.find(
        (item) => item.name === selectedLocation.time_zone_identifier,
      )?.id;

      // set services that checked
      const selectedServices = selectedLocation.sub_services;

      const serviceSewaMobil = serviceData.find((item) => item.name === 'Sewa Mobil');

      const currencySelected = dataCurrencies.data.find((item) => item.symbol === selectedLocation.currency);

      // mapping services
      serviceSewaMobil?.sub_services.forEach((item) => {
        if (item.name !== 'Daily' && item.name !== 'Airport Transfer') return;

        if (item.facilities.length !== 0) {
          for (let facilitiesChild of item.facilities) {
            if (
              selectedLocation.sub_services &&
              selectedLocation.sub_services.find((ser) => ser.id === facilitiesChild.id)
            ) {
              mappingServiceData.push({ id: facilitiesChild.id, name: facilitiesChild.name, checked: true });
            } else {
              mappingServiceData.push({ id: facilitiesChild.id, name: facilitiesChild.name, checked: false });
            }
          }
        } else {
          if (selectedLocation.sub_services && selectedLocation.sub_services.find((ser) => ser.id === item.id)) {
            mappingServiceData.push({ id: item.id, name: item.name, checked: true });
          } else {
            mappingServiceData.push({ id: item.id, name: item.name, checked: false });
          }
        }
      });

      setCityName(selectedLocation.name);
      setTimeZoneIdentifier(selectedTimeZoneIdentifier);
      setServices(selectedServices || []);
      setAllServices(mappingServiceData);
      setSelectedCurrency(currencySelected?.id);
      setIsSpecialAirportTransfer(selectedLocation?.is_special_airport_transfer);
    } else if (serviceData.length) {
      const serviceSewaMobil = serviceData.find((item) => item.name === 'Sewa Mobil');

      serviceSewaMobil?.sub_services.forEach((item) => {
        if (item.name !== 'Daily' && item.name !== 'Airport Transfer') return;

        if (item.facilities.length !== 0) {
          item.facilities.forEach((fac) => mappingServiceData.push({ id: fac.id, name: fac.name, checked: false }));
        } else {
          mappingServiceData.push({ id: item.id, name: item.name, checked: false });
        }
      });
      setAllServices(mappingServiceData);
    }
  }, [selectedLocation, serviceData, dataCurrencies]);

  useEffect(() => {
    if (checkEmptyObject(dataCurrencies)) return;
    setMappedCurrencies(() =>
      dataCurrencies.data
        // ?.filter((item) => item.is_enable)
        ?.map((item) => ({
          id: item.id,
          name: item.symbol,
        })),
    );
  }, [dataCurrencies]);

  useEffect(() => {
    if (checkEmptyObject(dataCurrencies)) return;
    setMappedCurrencies(() =>
      dataCurrencies.data
        // ?.filter((item) => item.is_enable)
        ?.map((item) => ({
          id: item.id,
          name: item.symbol,
        })),
    );
  }, [dataCurrencies]);

  return (
    <Modals
      setState={stateModalHandler}
      title={locationId ? 'Edit Daerah Sewa' : 'Tambah Daerah Sewa'}
      icon={<LocationFilledIcon fill="#009EF7" width="25px" height="25px" />}
    >
      <div className="city-modal">
        <form className="city-modal-form" onSubmit={submitHandler}>
          <div className="city-modal-form__form">
            <InputField
              label="Nama Daerah Sewa"
              htmlFor="nama-daerah-sewa"
              value={cityName}
              onChange={(e) => setCityName(e.target.value)}
              placeholder="Nama Daerah Sewa"
            />

            <SelectFieldSearch
              label="Time Zone Identifier"
              placeholder="Pilih Time Zone Identifier"
              htmlFor="time-zone-identifier"
              data={allTimeZone}
              value={timeZoneIdentifier}
              onClick={setTimeZoneIdentifier}
            />

            <SelectFieldSearch
              label="Currency"
              placeholder="Pilih Currency"
              htmlFor="currency"
              data={mappedCurrencies}
              value={selectedCurrency}
              onClick={setSelectedCurrency}
              disable={locationId}
            />
          </div>

          <div className="city-modal-form__services">
            <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
              <TabPane label="With Driver">
                <ServicesForm
                  services={services}
                  setServices={setServices}
                  item={allServices?.find((item) => item.name === 'With Driver')}
                />
              </TabPane>
              <TabPane label="Without Driver">
                <ServicesForm
                  services={services}
                  setServices={setServices}
                  item={allServices?.find((item) => item.name === 'Without Driver')}
                />
              </TabPane>
              <TabPane label="Airport Transfer">
                <ServicesForm
                  services={services}
                  setServices={setServices}
                  isSpecialAirportTransfer={isSpecialAirportTransfer}
                  setIsSpecialAirportTransfer={setIsSpecialAirportTransfer}
                  item={allServices?.find((item) => item.name === 'Airport Transfer')}
                />
              </TabPane>
            </Tabs>
          </div>

          {(!locationId || checkPermission(offCanvasMenu, currentMenu, 'update')) && (
            <Button width={192} style={{ marginLeft: 'auto' }} role="submit">
              {locationId ? 'Submit' : 'Tambah'}
            </Button>
          )}
        </form>
      </div>
    </Modals>
  );
};

export default AddCityModal;
