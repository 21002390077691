import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllInboxes, getInboxDetail } from 'features/notification-inbox/actions';
import { PaginationTable, TableWrapper } from 'components/Global';
import { ReactComponent as BellIcon } from 'icons/bell-filled-icon.svg';
import { ReactComponent as SuccessIcon } from 'icons/toast-success-icon.svg';
import { ReactComponent as OrderIcon } from 'icons/confirmation-filled-icon.svg';
import { ReactComponent as RejectedIcon } from 'icons/rejected-icon.svg';
import { ReactComponent as WarningIcon } from 'icons/warning-notif-icon.svg';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import { id } from 'date-fns/locale';
import NotificationDetail from './NotificationDetail';
import EmptyState from 'components/Global/EmptyState';

const NotificationInboxPageComponent = () => {
  const [showDetail, setShowDetail] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { inboxes, pagination } = useSelector((state) => state.notificationInbox.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllInboxes({ limit: 6, page: currentPage }));
  }, [currentPage]);

  const handleDetail = (e) => {
    const notifId = e.currentTarget.dataset['key'];
    dispatch(getInboxDetail(notifId));
    setShowDetail(true);
  };

  const getNotifIcon = (title) => {
    switch (title) {
      case 'pesanan baru':
      case 'Permintaan Penarikan Dana':
      case 'Pengajuan Pembatalan Tugas':
      case 'pengajuan pembatalan order':
      case 'berhasil membatalkan tugas':
      case 'orderan berhasil diambil driver':
      case 'notifikasi belum ada driver':
        return <OrderIcon className="notification__item--icon" />;
      case 'pengajuan hapus data ditolak':
        return <RejectedIcon className="notification__item--icon" />;
      case 'approval recalculate':
        return <WarningIcon className="notification__item--icon" />;
      default:
        return <SuccessIcon className="notification__item--icon" />;
    }
  };

  return (
    <div className="notification">
      {showDetail ? (
        <NotificationDetail
          setShowDetail={setShowDetail}
          getNotificationIcon={getNotifIcon}
          getAllInboxes={getAllInboxes({ limit: 6, page: currentPage })}
        />
      ) : (
        <div className="notification-list">
          <TableWrapper title="Notifikasi" icon={<BellIcon fill="#009EF7" width="25px" height="25px" />}>
            {inboxes?.length > 0 ? (
              <>
                {inboxes?.map((item) => (
                  <div
                    className={clsx('notification__item', !item.is_read && 'notification__item--unread')}
                    data-key={item.id}
                    key={item.id}
                    onClick={handleDetail}
                  >
                    <div className="notification__item--header">
                      {getNotifIcon(item.title?.toLowerCase())}
                      <div className="notification__item--body">
                        <h1 className="notification__item--body-title">{item.title}</h1>
                        <p className="notification__item--body-created-at">
                          {format(parseISO(item.created_at), 'dd LLLL yyyy, HH:mm', {
                            locale: id,
                          })}
                        </p>
                        <p className="notification__item--body-message">
                          {item.custom_data?.key === 'admin_withdraw_request'
                            ? '💰 Permintaan Penarikan Dana'
                            : item.title === 'Berhasil Membatalkan Tugas'
                            ? item.message.split('.')[0]
                            : item.title === 'Orderan Berhasil Diambil Driver' ||
                              item.title === 'Notifikasi Belum ada Driver'
                            ? item.message.split(',')[0]
                            : item.message}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <EmptyState />
            )}
          </TableWrapper>
          <PaginationTable
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            totalCount={pagination?.total_data}
          />
        </div>
      )}
    </div>
  );
};

export default NotificationInboxPageComponent;
