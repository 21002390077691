import React from 'react';
import { Button } from 'components/Global';
import { useAppContext } from 'components/Context/AppContext';
import { updateOrderCancelation } from 'features/orders/actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ActionButtons = ({ data, backHandler, RenderedProcessButton }) => {
  const { setShowRejectOrderModal, setShowConfirmation, setShowSpinner, showToast } = useAppContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRequestCancelOrder = () => {
    const payload = {
      transaction_key: data.transaction_key,
      status: 'REFUNDED',
    };

    setShowConfirmation({
      message: 'Apakah anda yakin ingin membatalkan pesanan ini?',
      show: true,
      onClick: async () => {
        setShowRejectOrderModal(false);

        try {
          setShowSpinner(true);
          await dispatch(updateOrderCancelation(payload)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Membatalkan Pesanan' });
          navigate(-1);
        } catch (err) {
          showToast({ type: 'error', message: 'Gagal Membatalkan Pesanan!' });
        } finally {
          setShowSpinner(false);
        }
      },
    });
  };

  const isOrderCancelled = data?.order_status === 'CANCELLED';
  const isOrderFinished = data?.order_status === 'FINISHED';
  const showCancelOrderBtn = !isOrderCancelled && !isOrderFinished && !data.order_approval_status;

  return (
    <div className="detail-order__transaction-buttons">
      <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
        Kembali
      </Button>

      {(data.order_status === 'CHECKOUT' || data.order_status === 'REVIEWING_IDENTITY') &&
      !data.order_approval_status ? (
        <Button size="sm" className="button" width={208} variant="danger" onClick={() => setShowRejectOrderModal(true)}>
          Tolak Orderan
        </Button>
      ) : null}

      {showCancelOrderBtn ? (
        <Button size="sm" className="button" width={208} variant="danger" onClick={handleRequestCancelOrder}>
          Cancel Order
        </Button>
      ) : null}

      <RenderedProcessButton />
    </div>
  );
};

export default ActionButtons;
