import React, { useEffect } from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as BellIcon } from 'icons/bell-filled-icon.svg';
// import { ReactComponent as SuccessIcon } from 'icons/toast-success-icon.svg';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Button, TableWrapper } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { id } from 'date-fns/locale';
import { fetchOrderById } from 'features/orders/actions';
import { setCurrency } from 'utils/functionality';

const ChatType = {
  'message from order detail': 'cs-order',
  'message from customer': 'cs-customer',
  'message from driver': 'cs-driver',
};

const NotificationDetail = ({ setShowDetail, getNotificationIcon, getAllInboxes }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inbox = useSelector((state) => state.notificationInbox.selected);
  const { data: orderData } = useSelector((state) => state.detailOrder);

  const handleClick = (title) => {
    if (inbox.custom_data?.key === 'admin_withdraw_request') {
      navigate(`/driver/withdraw?id=${inbox.custom_data.withdraw_id}`);
      return;
    }

    if (
      orderData.order_type_id === 7 &&
      (title === 'pengajuan pembatalan tugas' ||
        title === 'berhasil  membatalkan tugas' ||
        title === 'orderan berhasil diambil driver' ||
        title === 'notifikasi belum ada driver (h-1 hari)' ||
        title === 'notifikasi belum ada driver (h-20 menit)' ||
        title === 'notifikasi belum ada driver (h-15 menit)' ||
        title === 'notifikasi belum ada driver')
    ) {
      navigate({
        pathname: `/driver/driver-task`,
        search: createSearchParams({
          key: inbox.custom_data.order_id,
          customer_Id: inbox.custom_data?.customer_id
            ? inbox.custom_data?.customer_id
            : inbox.custom_data?.user_id
            ? inbox.custom_data.user_id
            : orderData?.customer_id,
          id: inbox.custom_data.task_id,
        }).toString(),
      });
      return;
    }

    if (title === 'customer updated bank data') {
      navigate(`/refund-dana/${inbox.custom_data.refund_order_id}`);
      return;
    }

    const orderType =
      orderData.order_type_id === 1
        ? orderData.order_detail.without_driver
          ? 'without-driver'
          : 'with-driver'
        : 'airport-transfer';
    navigate({
      pathname: `/${orderType}/detail/${orderData.transaction_key}`,
      search: createSearchParams({
        customerId: inbox.custom_data?.customer_id
          ? inbox.custom_data?.customer_id
          : inbox.custom_data?.user_id
          ? inbox.custom_data.user_id
          : orderData?.customer_id,
      }).toString(),
    });
  };

  const handleShowMessage = () => {
    navigate({
      pathname: '/messages',
      search: createSearchParams({
        chatType: ChatType[inbox.title.toLowerCase()],
      }).toString(),
    });
  };

  const handleBack = () => {
    setShowDetail(false);
    dispatch(getAllInboxes);
  };

  const gotFeeCurrency = (fee, currency) => {
    const RightFee =
      fee && currency === 'IDR'
        ? setCurrency(fee).replace('Rp', `${currency}`)
        : fee && currency === 'SGD'
        ? `${currency} ${fee.toFixed(2)}`
        : fee === 0
        ? `${currency} 0`
        : '-';
    return RightFee;
  };

  useEffect(() => {
    if (!Object.prototype.hasOwnProperty.call(inbox, 'custom_data')) return;
    if (inbox.custom_data.order_id) {
      dispatch(fetchOrderById(inbox.custom_data.order_id));
    } else if (inbox.custom_data.transaction_key) {
      dispatch(fetchOrderById(inbox.custom_data.transaction_key));
    }
  }, [inbox]);

  const showButtonDetailMessage =
    inbox?.title?.toLowerCase() === 'message from order detail' ||
    inbox?.title?.toLowerCase() === 'message from customer' ||
    inbox?.title?.toLowerCase() === 'message from driver';

  return (
    <div className="notification-detail">
      <div className="notification-detail__back-btn" onClick={handleBack}>
        <LeftArrow />
        <p>Kembali</p>
      </div>

      <div className="notification-detail__body">
        <TableWrapper title="Notifikasi" icon={<BellIcon fill="#009EF7" width="25px" height="25px" />}>
          <div className="notification-detail__item">
            <div className="notification-detail__item--header">
              {getNotificationIcon(inbox.title?.toLowerCase())}
              <div className="notification-detail__item--body">
                <h1 className="notification-detail__item--body-title">{inbox.title}</h1>
                <p className="notification-detail__item--body-created-at">
                  {inbox.created_at &&
                    format(parseISO(inbox.created_at), 'dd LLLL yyyy, HH:mm', {
                      locale: id,
                    })}
                </p>
                <p className="notification-detail__item--body-message">
                  {inbox.custom_data?.key === 'admin_withdraw_request' ? (
                    <div className="withdraw-notification">
                      <p>💰 Permintaan Penarikan Dana</p>
                      <p>
                        Driver {inbox?.custom_data?.driver_name} telah mengajukan permintaan penarikan dana dengan
                        detail sebagai berikut :
                      </p>
                      <div>
                        <span />
                        <p>
                          Jumlah Penarikan: {gotFeeCurrency(inbox?.custom_data?.amount, inbox?.custom_data?.currency)}
                        </p>
                      </div>
                      <div>
                        <span />
                        <p>
                          Tanggal Pengajuan:{' '}
                          {format(parseISO(inbox?.custom_data?.date_request), 'dd MMMM yyyy', { locale: id })}
                        </p>
                      </div>
                      <p>
                        Silakan tinjau dan proses permintaan ini melalui sistem admin. Pastikan semua data sudah sesuai
                        sebelum melakukan konfirmasi. ✅
                      </p>
                      <p className="footer-note">
                        Catatan: Admin sebaiknya memeriksa riwayat transaksi driver sebelum menyetujui permintaan ini
                      </p>
                    </div>
                  ) : inbox.title === 'Berhasil Membatalkan Tugas' ||
                    inbox.title === 'Orderan Berhasil Diambil Driver' ||
                    inbox.title === 'Notifikasi Belum ada Driver' ? (
                    <p dangerouslySetInnerHTML={{ __html: inbox.message }} />
                  ) : (
                    inbox.message
                  )}

                  {/* {inbox.message} */}
                </p>
              </div>
            </div>

            {showButtonDetailMessage ? (
              <div className="notification-detail__item--button">
                <Button onClick={handleShowMessage} width="135px" height="39px" className="button-order">
                  Lihat Pesan
                </Button>
              </div>
            ) : (
              <div className="notification-detail__item--button">
                <Button
                  onClick={() => handleClick(inbox.title?.toLowerCase())}
                  width="135px"
                  height="39px"
                  className="button-order"
                >
                  Lihat Order
                </Button>
              </div>
            )}
          </div>
        </TableWrapper>
      </div>
    </div>
  );
};

export default NotificationDetail;
