import React from 'react';
import { setCurrency } from 'utils/functionality';
import SelectFieldShuttle from 'components/Global/SelectFieldShuttle';
import { InputField } from 'components/Global';
import { ReactComponent as LocationTypeIcon } from 'icons/type-locatin-icon.svg';
import { ReactComponent as PlusCircleIcon } from 'icons/plus-icon-circle-blue.svg';
import { ReactComponent as Mappin } from 'icons/mappin-icon.svg';
import { ReactComponent as Xsign } from 'icons/x-sign-icon.svg';
import InputPriceAirportPackage from 'components/Global/InputPriceAirportPackage';
import clsx from 'clsx';

const TableLocations = ({
  airportLocationData,
  pickUpLocation,
  setPickUpLocation,
  dropOffLocation,
  setDropOffLocation,
  pickUpLocationType,
  dropOffLocationType,
  price,
  setPrice,
  route,
  setRoute,
  onAddRoute,
  onDelete,
  mode,
  selectedLocation,
  rateSGDtoIDR,
}) => {
  const onEditPrice = (value, id) => {
    const copyRouteArray = [...route];
    const choosedRoute = copyRouteArray.find((item) => item.id == id);

    if (choosedRoute) {
      copyRouteArray[copyRouteArray.indexOf(choosedRoute)].fee = +value;
      setRoute(copyRouteArray);
    }
  };

  return (
    <div className="table-locations">
      {rateSGDtoIDR && selectedLocation?.currency === 'SGD' ? (
        <>
          <p> Nilai Mata Uang:</p>
          <p className="exchange-rate">1 SGD = {setCurrency(rateSGDtoIDR)?.replace('Rp', 'IDR')}</p>
        </>
      ) : null}

      <h3>Pilih Rute</h3>
      <div className="table-locations__table-wrapper">
        <table className="table-content">
          <thead>
            <th>Lokasi Penjemputan</th>
            <th>Lokasi Pengantaran</th>
            <th>Harga</th>
          </thead>
          <tbody>
            {route &&
              route.length !== 0 &&
              route.map((item) => (
                <tr key={item.id}>
                  <td>
                    <div className="location-wrapper">
                      <div className="grid-item">
                        <InputField
                          icon={<Mappin />}
                          iconPosition="start"
                          placeholder="Tipe Daerah"
                          className="location-wrapper__type-location"
                          value={item.pickup_location_name || ''}
                          readOnly
                        />
                      </div>
                      <div>
                        <InputField
                          icon={<LocationTypeIcon />}
                          iconPosition="start"
                          placeholder="Tipe Daerah"
                          className="location-wrapper__type-location"
                          value={item.pickup_location_id_type_name || ''}
                          readOnly
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="location-wrapper">
                      <div className="grid-item">
                        <InputField
                          icon={<Mappin />}
                          iconPosition="start"
                          placeholder="Tipe Daerah"
                          className="location-wrapper__type-location"
                          value={item.dropoff_location_name}
                          readOnly
                        />
                      </div>
                      <div>
                        <InputField
                          icon={<LocationTypeIcon />}
                          iconPosition="start"
                          placeholder="Tipe Daerah"
                          className="location-wrapper__type-location"
                          value={item.dropoff_location_type_name}
                          readOnly
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="price-wrapper">
                      <InputPriceAirportPackage
                        className={clsx('price-wrapper__price', mode === 'detail' ? 'read-only' : '')}
                        placeholder={'IDR'}
                        value={setCurrency(item.fee)?.replace('Rp', selectedLocation?.currency || '')}
                        onChange={(value) => onEditPrice(value, item.id)}
                        readOnly={mode === 'detail'}
                      />
                      {(mode === 'add-item' || mode === 'edit') && (
                        <div className="price-wrapper__xsign" onClick={() => onDelete(item.id)} role="button">
                          <Xsign />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}

            {/* ROW TAMBAH ROUTE */}
            {(mode === 'add-item' || mode === 'edit') && (
              <tr>
                <td>
                  <div className="location-wrapper">
                    <div className="grid-item">
                      <SelectFieldShuttle
                        className="location-wrapper__location"
                        data={airportLocationData.shuttle}
                        value={pickUpLocation.id}
                        onChange={setPickUpLocation}
                        placeholder="Pilih Daerah"
                        searchFeature
                      />
                    </div>
                    <div>
                      <InputField
                        icon={<LocationTypeIcon />}
                        iconPosition="start"
                        placeholder="Tipe Daerah"
                        className="location-wrapper__type-location"
                        value={pickUpLocationType.name || ''}
                        readOnly
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className="location-wrapper">
                    <div className="grid-item">
                      <SelectFieldShuttle
                        className="location-wrapper__location"
                        data={airportLocationData.shuttle}
                        value={dropOffLocation.id}
                        onChange={setDropOffLocation}
                        placeholder="Pilih Daerah"
                        searchFeature
                      />
                    </div>
                    <div>
                      <InputField
                        icon={<LocationTypeIcon />}
                        iconPosition="start"
                        placeholder="Tipe Daerah"
                        className="location-wrapper__type-location"
                        value={dropOffLocationType.name || ''}
                        readOnly
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className="price-wrapper">
                    <InputField
                      className="price-wrapper__price"
                      type="number"
                      placeholder={`${selectedLocation?.currency || ''} 0`}
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          {(mode === 'add-item' || mode === 'edit') && (
            <tfoot>
              <td colSpan="3">
                <div className="add-route-wrapper">
                  <div onClick={onAddRoute} role="button">
                    <PlusCircleIcon />
                    <p>Tambah Rute</p>
                  </div>
                </div>
              </td>
            </tfoot>
          )}
        </table>
      </div>
    </div>
  );
};

export default TableLocations;
