import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import { Button, InputField, TableWrapper } from 'components/Global';
import { useNavigate } from 'react-router-dom';
import InputTelpField from 'components/Global/InputTelpField';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { checkEmptyObject, setCurrency } from 'utils/functionality';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailDriver, getVehiclePackages } from 'features/drivers/actions';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import BlueMappin from 'icons/mappin-icon-blue.svg';
import YellowMappin from 'icons/mappin-icon-yellow.svg';
import 'leaflet/dist/leaflet.css';
import { useAppContext } from 'components/Context/AppContext';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

const AirportTransferSingaporeTask = ({ data, setShowImage, selectedDriverTaskData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customIconBlue = L.icon({
    iconUrl: BlueMappin,
    iconSize: [20, 20],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  });

  const customIconYellow = L.icon({
    iconUrl: YellowMappin,
    iconSize: [20, 20],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  });

  const { setShowConfirmation, setShowCancelDriverTask } = useAppContext();

  const { selected: selectedDriver } = useSelector((state) => state.drivers);

  const cancelHandler = () => {
    setShowConfirmation({
      show: true,
      message: 'Apakah anda ingin membatalkan Task Order ini?',
      onClick: async () => {
        setShowCancelDriverTask(true);
      },
    });
  };

  const gotRightCurrency = (fee, currency) => {
    const RightFee =
      fee && currency === 'IDR'
        ? setCurrency(fee).replace('Rp', `${currency}`)
        : fee && currency === 'SGD'
        ? `${currency} ${fee.toFixed(2)}`
        : '-';
    return RightFee;
  };

  useEffect(() => {
    if (!selectedDriverTaskData || !selectedDriverTaskData?.driver_id) return;

    dispatch(getDetailDriver(selectedDriverTaskData.driver_id));
  }, [selectedDriverTaskData]);

  useEffect(() => {
    if (!selectedDriver || checkEmptyObject(selectedDriver)) return;

    const locationId = selectedDriver?.user_location?.location_id;
    dispatch(getVehiclePackages(locationId));
  }, [selectedDriver]);

  return (
    <TableWrapper icon={<CarIcon fill="#009EF7" width="25px" height="25px" />} title="Driver Task">
      <h2>Detail Order</h2>
      <div className="detail-driver-task__order-detail">
        <InputField label="Nama Lengkap" disable readOnly value={data?.user_name || '-'} />

        <InputField label="No Order" disable readOnly value={data?.order_key || '-'} />

        <InputTelpField
          label="No Handphone"
          countryCodeValue={data?.phone_country_code || '+62'}
          disable
          readOnly
          value={data?.wa_number || '-'}
        />

        <InputField
          label="Tanggal Sewa"
          disable
          readOnly
          value={
            data?.order_detail?.start_booking_date
              ? format(new Date(data?.order_detail?.start_booking_date), 'dd MMMM yyyy', { locale: id })
              : '-'
          }
        />
        <InputField
          label="Jam Mulai"
          disable
          readOnly
          value={
            data?.order_detail?.start_booking_date && data?.order_detail?.start_booking_time
              ? format(
                  new Date(`${data?.order_detail?.start_booking_date} ${data?.order_detail?.start_booking_time}`),
                  'hh:mm aa',
                  { locale: id },
                )
              : '-'
          }
        />

        <InputField label="Flight Numbers" disable readOnly value={data?.order_detail?.flight_number || '-'} />

        <div className="detail-driver-task__order-detail__passengers-baggage">
          <InputField
            label="Penumpang"
            disable
            readOnly
            value={
              data?.order_detail?.adult_passenger || data?.order_detail?.child_passenger
                ? `${data?.order_detail?.adult_passenger} Adult, ${data?.order_detail?.child_passenger} Child`
                : '-'
            }
          />
          <InputField
            label="Koper"
            disable
            readOnly
            value={
              data?.order_detail?.large_suitcase || data?.order_detail?.regular_suitcase
                ? `${data?.order_detail?.large_suitcase} Suitcase XL, ${data?.order_detail?.regular_suitcase} Luggage`
                : '-'
            }
          />
        </div>

        <InputField label="Kategori Mobil" disable readOnly value={'Singapore Driver Car'} iconPosition="end" />

        <InputField label="Pick Up" disable readOnly value={data?.order_detail?.rental_delivery_location || '-'} />

        <InputField label="Drop Off" disable readOnly value={data?.order_detail?.rental_return_location || '-'} />

        {selectedDriverTaskData?.order?.order_detail?.origin && (
          <MapContainer
            center={[
              selectedDriverTaskData?.order?.order_detail?.origin?.lat,
              selectedDriverTaskData?.order?.order_detail?.origin?.long,
            ]}
            zoom={13}
            style={{ height: '203px', width: '100%' }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker
              position={[
                selectedDriverTaskData?.order?.order_detail?.origin?.lat,
                selectedDriverTaskData?.order?.order_detail?.origin?.long,
              ]}
              icon={customIconBlue}
            >
              <Popup>{selectedDriverTaskData?.order?.order_detail?.rental_delivery_location}</Popup>
            </Marker>
          </MapContainer>
        )}

        {selectedDriverTaskData?.order?.order_detail?.destination && (
          <MapContainer
            center={[
              selectedDriverTaskData?.order?.order_detail?.destination?.lat,
              selectedDriverTaskData?.order?.order_detail?.destination?.long,
            ]}
            zoom={13}
            style={{ height: '203px', width: '100%' }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker
              position={[
                selectedDriverTaskData?.order?.order_detail?.destination?.lat,
                selectedDriverTaskData?.order?.order_detail?.destination?.long,
              ]}
              icon={customIconYellow}
            >
              <Popup>{selectedDriverTaskData?.order?.order_detail?.rental_return_location}</Popup>
            </Marker>
          </MapContainer>
        )}

        <InputField
          label="Detail Lokasi"
          disable
          readOnly
          value={data?.order_detail?.rental_delivery_location_detail || '-'}
        />

        <InputField
          label="Detail Lokasi"
          disable
          readOnly
          value={data?.order_detail?.rental_return_location_detail || '-'}
        />
      </div>

      {selectedDriverTaskData?.status !== 'OPEN' && (
        <>
          <h2>Detail Driver</h2>
          <div className="detail-driver-task__driver-detail">
            <div className="identity-image-sim">
              <h3>Foto SIM</h3>
              <div className="image">
                <div
                  className="image-wrapper"
                  onClick={() => {
                    if (selectedDriver?.PersonalInfos?.sim) {
                      return setShowImage({
                        show: true,
                        image: `${IMAGE_BASE_URL}${selectedDriver?.PersonalInfos?.sim}`,
                      });
                    }
                  }}
                  style={{
                    cursor: selectedDriver?.PersonalInfos?.sim ? 'pointer' : 'not-allowed',
                  }}
                >
                  {selectedDriver?.PersonalInfos?.sim ? (
                    <img src={`${IMAGE_BASE_URL}${selectedDriver?.PersonalInfos?.sim}`} alt="sim image" />
                  ) : (
                    <div className="no-available">
                      <img src="/assets/image/no-image-available.png" className="img" />
                      <p>No Image Available</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="identity-image-ktp">
              <h3>Foto KTP</h3>
              <div className="image">
                <div
                  className="image-wrapper"
                  onClick={() => {
                    if (selectedDriver?.PersonalInfos?.ktp) {
                      return setShowImage({
                        show: true,
                        image: `${IMAGE_BASE_URL}${selectedDriver?.PersonalInfos?.ktp}`,
                      });
                    }
                  }}
                  style={{
                    cursor: selectedDriver?.PersonalInfos?.ktp ? 'pointer' : 'not-allowed',
                  }}
                >
                  {selectedDriver?.PersonalInfos?.ktp ? (
                    <img src={`${IMAGE_BASE_URL}${selectedDriver?.PersonalInfos?.ktp}`} alt="sim image" />
                  ) : (
                    <div className="no-available">
                      <img src="/assets/image/no-image-available.png" className="img" />
                      <p>No Image Available</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <InputField label="Mobil" disable readOnly value={selectedDriver?.vehicle?.name || '-'} />

            <InputField label="Plat Nomer" disable readOnly value={selectedDriver?.vehicle?.license_number || '-'} />

            <InputField
              label="Fee Per-Task"
              disable
              readOnly
              value={
                selectedDriverTaskData?.fee_per_task
                  ? gotRightCurrency(selectedDriverTaskData?.fee_per_task, data?.currency)
                  : '-'
              }
            />

            <InputField label="ID Driver" disable readOnly value={selectedDriverTaskData?.driver_id || '-'} />
          </div>
        </>
      )}

      <div className="detail-driver-task__buttons">
        <Button variant="outline" onClick={() => navigate(-1)} width={192}>
          Kembali
        </Button>

        {selectedDriverTaskData?.status !== 'CANCEL' && selectedDriverTaskData?.status !== 'FINISH' && (
          <Button variant="danger" onClick={cancelHandler} width={192}>
            Cancel Task
          </Button>
        )}
      </div>
    </TableWrapper>
  );
};

export default AirportTransferSingaporeTask;
