import React, { useEffect, useState } from 'react';
import { PaginationTable, Table, TableWrapper } from 'components/Global';
import { ReactComponent as AirportTransferCarFilledIcon } from 'icons/airport-transfer-car-filled-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppContext } from 'components/Context/AppContext';
import useDebounce from 'utils/useDebounce';
import { getAllAirportLocationType } from 'features/airport-location-type/actions';
import EmptyState from 'components/Global/EmptyState';
import { deleteAirportLocation, getAllAirportLocation } from 'features/airport-location/actions';
import { getRentalLocationByServices } from 'features/rental-location/actions';
import { getServices } from 'features/services/actions';
import SearchBar from 'components/Global/TableWrapper/SearchBar';
import VehiclesFilter from 'components/Global/VehiclesFilter';
import { checkPermission } from 'utils/functionality';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Lokasi', value: 'location_name' },
  { header: 'Nama Daerah', value: 'name' },
  { header: 'Tipe Daerah', value: 'location_type' },
];

const Daerah = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { setShowSpinner, setShowAddAirportLocation, showToast, setShowConfirmation } = useAppContext();

  const { data: airportLocationData, isLoading } = useSelector((state) => state.airportLocation);
  const { data: servicesData } = useSelector((state) => state.services);
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);
  const { data: airportLocationTypeData } = useSelector((state) => state.airportLocationType);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams] = useSearchParams();
  const [tableData, setTableData] = useState([]);
  const [rentalLocation, setRentalLocation] = useState([]);
  const [airportLocationType, setAirportLocationType] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [sortBy, setSortBy] = useState({ count: 0, sortValue: '' });
  const [orderSeq, setOrderSeq] = useState('desc');
  const [filters, setFilters] = useState([]);

  const searchValueDebounce = useDebounce(searchValue, 1000);

  const locationId = searchParams.getAll('location_id');
  const locationTypeId = searchParams.getAll('location_type_id');
  const id = searchParams.get('id');

  // FUNCTION TO EDIT
  const editHandler = (e) => {
    const masterDaerahId = e.target.parentElement.parentElement.dataset['key'];
    navigate(`${location.pathname}${location.search}&id=${masterDaerahId}`);
  };

  // FUNCTION TO DELETE
  const deleteHandler = (e) => {
    const masterDaerahId = e.target.parentElement.parentElement.dataset['key'];

    const filterLocationId = locationId
      ? locationId.map((item) => ({ filter_key: 'location_id', filter_value: +item }))
      : [];
    const filterLocationTypeId = locationTypeId
      ? locationTypeId.map((item) => ({ filter_key: 'location_type_id', filter_value: +item }))
      : [];

    setShowConfirmation({
      message: 'Apakah anda yakin ingin hapus Daerah',
      show: true,
      onClick: async () => {
        try {
          await dispatch(deleteAirportLocation(masterDaerahId)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Hapus Daerah' });
          dispatch(
            getAllAirportLocation({
              limit: 10,
              page: currentPage,
              sortBy: sortBy.sortValue,
              sortOrder: orderSeq,
              search: searchValue,
              filters:
                [...filterLocationId, ...filterLocationTypeId].length === 0
                  ? undefined
                  : [...filterLocationId, ...filterLocationTypeId],
            }),
          );
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          showToast({ type: 'error', message: 'Gagal Hapus Daerah' });
        }
      },
    });
  };

  // FUNTION FILTER BY LOCATION
  const handleFilterLocations = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (value) {
      if (checked) {
        const newRentalLocations = [...rentalLocation];
        const filterLocations = newRentalLocations.find((item) => item.id == value);

        newRentalLocations[newRentalLocations.indexOf(filterLocations)].checked = true;

        setRentalLocation(newRentalLocations);

        setFilters((prevState) => [...prevState, { filter_key: 'location_id', filter_value: filterLocations?.id }]);

        const params = new URLSearchParams(searchParams);
        params.append('location_id', filterLocations?.id);
        navigate(`${location.pathname}?${params.toString()}`);
      } else {
        const newRentalLocations = [...rentalLocation];
        const filterLocations = newRentalLocations.find((item) => item.id == value);

        newRentalLocations[newRentalLocations.indexOf(filterLocations)].checked = false;

        setRentalLocation(newRentalLocations);

        const filteredFilters = filters.filter((item) => {
          if (item.filter_key === 'location_id') {
            if (item.filter_value !== filterLocations?.id) {
              return item;
            }
          } else {
            return item;
          }
        });
        setFilters(filteredFilters);

        const params = new URLSearchParams(searchParams);
        const paramsArray = params.getAll('location_id').filter((item) => item != filterLocations?.id);
        params.delete('location_id');
        paramsArray.forEach((item) => params.append('location_id', item));
        navigate(`${location.pathname}?${params.toString()}`);
      }
    }
  };

  // FUNTION FILTER BY LOCATION TYPE
  const handleFilterLocationType = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (value) {
      if (checked) {
        const newRentalAirportLocationType = [...airportLocationType];
        const filterAirportlocationType = newRentalAirportLocationType.find((item) => item.id == value);

        newRentalAirportLocationType[newRentalAirportLocationType.indexOf(filterAirportlocationType)].checked = true;

        setAirportLocationType(newRentalAirportLocationType);

        setFilters((prevState) => [
          ...prevState,
          { filter_key: 'location_type_id', filter_value: filterAirportlocationType?.id },
        ]);

        const params = new URLSearchParams(searchParams);
        params.append('location_type_id', filterAirportlocationType?.id);
        navigate(`${location.pathname}?${params.toString()}`);
      } else {
        const newRentalAirportLocationType = [...airportLocationType];
        const filterAirportlocationType = newRentalAirportLocationType.find((item) => item.id == value);

        newRentalAirportLocationType[newRentalAirportLocationType.indexOf(filterAirportlocationType)].checked = false;

        setAirportLocationType(newRentalAirportLocationType);

        const filteredFilters = filters.filter((item) => {
          if (item.filter_key === 'location_type_id') {
            if (item.filter_value !== filterAirportlocationType?.id) {
              return item;
            }
          } else {
            return item;
          }
        });
        setFilters(filteredFilters);

        const params = new URLSearchParams(searchParams);
        const paramsArray = params.getAll('location_type_id').filter((item) => item != filterAirportlocationType?.id);
        params.delete('location_type_id');
        paramsArray.forEach((item) => params.append('location_type_id', item));
        navigate(`${location.pathname}?${params.toString()}`);
      }
    }
  };

  // FUNCTION TO SORT
  const sortByHandler = (sortValue) => {
    const filterLocationId = locationId
      ? locationId.map((item) => ({ filter_key: 'location_id', filter_value: +item }))
      : [];
    const filterLocationTypeId = locationTypeId
      ? locationTypeId.map((item) => ({ filter_key: 'location_type_id', filter_value: +item }))
      : [];

    if (sortValue === sortBy.sortValue) {
      if (sortBy.count === 1) {
        setSortBy({ count: sortBy.count + 1, sortValue });
        setOrderSeq('desc');
        if (currentPage === 1) {
          dispatch(
            getAllAirportLocation({
              page: 1,
              limit: 10,
              sortBy: sortValue,
              sortOrder: 'desc',
              search: searchValue,
              filters:
                [...filterLocationId, ...filterLocationTypeId].length === 0
                  ? undefined
                  : [...filterLocationId, ...filterLocationTypeId],
            }),
          );
          localStorage.setItem(
            'airport-location',
            JSON.stringify({
              page: 1,
              sortBy: sortValue,
              sortOrder: 'desc',
              filters: [...filterLocationId, ...filterLocationTypeId],
              search: searchValue,
            }),
          );
        } else {
          setCurrentPage(1);
        }
      } else if (sortBy.count === 2) {
        setSortBy({ count: 0, sortValue: '' });
        setOrderSeq('desc');
        if (currentPage === 1) {
          dispatch(
            getAllAirportLocation({
              page: 1,
              limit: 10,
              sortBy: '',
              sortOrder: 'desc',
              search: searchValue,
              filters:
                [...filterLocationId, ...filterLocationTypeId].length === 0
                  ? undefined
                  : [...filterLocationId, ...filterLocationTypeId],
            }),
          );
          localStorage.setItem(
            'airport-location',
            JSON.stringify({
              page: 1,
              sortBy: '',
              sortOrder: 'desc',
              filters: [...filterLocationId, ...filterLocationTypeId],
              search: searchValue,
            }),
          );
        } else {
          setCurrentPage(1);
        }
      }
    } else {
      setSortBy({ count: 1, sortValue });
      setOrderSeq('asc');
      if (currentPage === 1) {
        dispatch(
          getAllAirportLocation({
            page: 1,
            limit: 10,
            sortBy: sortValue,
            sortOrder: 'asc',
            filters:
              [...filterLocationId, ...filterLocationTypeId].length === 0
                ? undefined
                : [...filterLocationId, ...filterLocationTypeId],
            search: searchValue,
          }),
        );
        localStorage.setItem(
          'airport-location',
          JSON.stringify({
            page: 1,
            sortBy: sortValue,
            sortOrder: 'asc',
            filters: [...filterLocationId, ...filterLocationTypeId],
            search: searchValue,
          }),
        );
      } else {
        setCurrentPage(1);
      }
    }
  };

  // WILL SHOW MODAL IF ID PARAMS IS EXIST
  useEffect(() => {
    if (id) {
      setShowAddAirportLocation(true);
      return;
    }
  }, [id]);

  // WILL FILTER DATA IF FILTERS IS EXIST
  useEffect(() => {
    if (currentPage === 1) {
      dispatch(
        getAllAirportLocation({
          page: 1,
          limit: 10,
          search: searchValueDebounce ? searchValueDebounce : undefined,
          sortBy: sortBy.sortValue,
          sortOrder: orderSeq,
          filters: filters.length === 0 ? undefined : filters,
        }),
      );
      localStorage.setItem(
        'airport-location',
        JSON.stringify({
          page: 1,
          sortBy: sortBy.sortValue,
          sortOrder: orderSeq,
          filters,
          search: searchValueDebounce,
        }),
      );
    } else {
      setCurrentPage(1);
    }
  }, [filters, searchValueDebounce]);

  // WILL CALL WHEN MOVE TO ANOTHER PAGE
  useEffect(() => {
    const filterLocationId = locationId
      ? locationId.map((item) => ({ filter_key: 'location_id', filter_value: +item }))
      : [];
    const filterLocationTypeId = locationTypeId
      ? locationTypeId.map((item) => ({ filter_key: 'location_type_id', filter_value: +item }))
      : [];

    dispatch(
      getAllAirportLocation({
        page: currentPage,
        limit: 10,
        search: searchValue,
        sortBy: sortBy.sortValue,
        sortOrder: orderSeq,
        filters:
          [...filterLocationId, ...filterLocationTypeId].length === 0
            ? undefined
            : [...filterLocationId, ...filterLocationTypeId],
      }),
    );
    dispatch(getAllAirportLocationType({ page: 1, limit: 10 }));
    dispatch(getServices());
    dispatch(getAllAirportLocationType({ page: 1, limit: 10 }));
    localStorage.setItem(
      'airport-location',
      JSON.stringify({
        page: currentPage,
        sortBy: sortBy.sortValue,
        sortOrder: orderSeq,
        search: searchValue,
        filters: [...filterLocationId, ...filterLocationTypeId],
      }),
    );
  }, [currentPage]);

  // MAPPING RENTAL LOCATION FILTER CHECKBOX
  useEffect(() => {
    if (!rentalLocationData || rentalLocationData.length === 0) return;

    let newArrayLocation = [];

    if (locationId) {
      const filterLocations = rentalLocationData.filter((item) => locationId.includes(item.id.toString()));
      newArrayLocation = rentalLocationData.map((item) => {
        return { ...item, checked: filterLocations.includes(item) };
      });
    } else {
      newArrayLocation = rentalLocationData.map((item) => {
        return { ...item, checked: false };
      });
    }

    setRentalLocation(newArrayLocation);
  }, [rentalLocationData]);

  // MAPPING RENTAL LOCATION TYPE FILTER CHECKBOX
  useEffect(() => {
    if (
      !airportLocationTypeData ||
      !Object.keys(airportLocationTypeData).length ||
      !airportLocationTypeData.data ||
      airportLocationTypeData.length === 0
    )
      return;

    let newArrayLocationType = [];

    if (locationTypeId) {
      const filterLocationsType = airportLocationTypeData.data.filter((item) =>
        locationTypeId.includes(item.id.toString()),
      );
      newArrayLocationType = airportLocationTypeData.data.map((item) => {
        return { ...item, checked: filterLocationsType.includes(item) };
      });
    } else {
      newArrayLocationType = airportLocationTypeData.data.map((item) => {
        return { ...item, checked: false };
      });
    }

    setAirportLocationType(newArrayLocationType);
  }, [airportLocationTypeData]);

  // GET RENTAL LOCATION BY SERVICE AIRPORT TRANSFER
  useEffect(() => {
    if (!servicesData || !servicesData.length) return;

    const carService = servicesData?.find((item) => item.name === 'Sewa Mobil');

    const airportServicesId = carService?.sub_services.find((item) => item.name === 'Airport Transfer')?.id;
    dispatch(getRentalLocationByServices({ service_id: carService?.id, sub_service_id: airportServicesId }));
  }, [servicesData]);

  // MAPPING NEW DATA
  useEffect(() => {
    if (
      !airportLocationData ||
      !Object.keys(airportLocationData).length ||
      !airportLocationData.shuttle ||
      !rentalLocationData ||
      !rentalLocationData.length
    )
      return;

    const mappingAirportLocation = airportLocationData.shuttle.map((item) => ({
      id: item.id,
      location_name: rentalLocationData.find((el) => el.id === item.location_id)?.name,
      name: item.name,
      location_type: item.airport_location_type?.name || '-',
    }));

    setTableData(mappingAirportLocation);
  }, [airportLocationData, rentalLocationData]);

  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  return (
    <TableWrapper
      icon={<AirportTransferCarFilledIcon fill="#009EF7" width="25px" height="25px" />}
      title="Airport Transfer Car"
      style={{ minHeight: '603px', position: 'relative' }}
    >
      <div className="filter-wrapper">
        <VehiclesFilter placeholder="Filter By Location" data={rentalLocation} onChange={handleFilterLocations} />
        <VehiclesFilter
          className="filter-by-location-type"
          placeholder="Filter By Tipe Daerah"
          data={airportLocationType}
          onChange={handleFilterLocationType}
        />

        <SearchBar
          type="text"
          value={searchValue}
          placeholder="Search by Name"
          onChange={setSearchValue}
          style={{ marginLeft: 8 }}
        />
      </div>

      {(!airportLocationData ||
        !Object.keys(airportLocationData).length ||
        !airportLocationData.shuttle ||
        !airportLocationData.shuttle.length) &&
      !isLoading ? (
        <EmptyState />
      ) : (
        <>
          <Table
            column={column}
            data={tableData}
            actionBtn
            onEdit={checkPermission(offCanvasMenu, currentMenu, 'update') ? editHandler : false}
            onDel={deleteHandler}
            currentPage={currentPage}
            sort
            columnToSort={['Lokasi', 'Nama Daerah', 'Tipe Daerah']}
            sortBy={sortBy}
            sortByHandler={sortByHandler}
          />
          <PaginationTable
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            totalCount={airportLocationData?.pagination?.total_data}
          />
        </>
      )}
    </TableWrapper>
  );
};

export default Daerah;
