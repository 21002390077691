import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRefund, getRefundReports, importRefundReports } from 'features/refund/actions';
import { checkEmptyObject, setCurrency } from 'utils/functionality';
import { Button, PaginationTable, Table, TableWrapper } from 'components/Global';
import EmptyState from 'components/Global/EmptyState';
import FilterTransaction from 'components/Global/TransactionFilter';
import LoadingSpinner from 'components/Global/LoadingSpinner';
import { ReactComponent as RefundIcon } from 'icons/refund-filled-icon.svg';
import { ReactComponent as DownloadIcon } from 'icons/download-excel.svg';
// import { ReactComponent as UploadIcon } from 'icons/upload-excel.svg';
import { useAppContext } from 'components/Context/AppContext';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Lengkap', value: 'customer_name' },
  { header: 'Kode Order', value: 'order_key' },
  { header: 'Jumlah Bayar', value: 'refund_amount' },
  { header: 'Status', value: 'status' },
];

const FILTER_DATA = [
  {
    id: 'CREATED',
    name: 'Belum Diproses',
    value: false,
  },
  {
    id: 'PROCESSED',
    name: 'Diproses',
    value: false,
  },
  {
    id: 'TRANSFERED',
    name: 'Selesai',
    value: false,
  },
  {
    id: 'REJECTED',
    name: 'Batal',
    value: false,
  },
];

const RefundComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const uploadRef = useRef(null);

  const { showToast, setShowConfirmation } = useAppContext();

  const { data: refundData, isLoading: refundDataLoading } = useSelector((state) => state.refund);

  const [currentPage, setCurrentPage] = useState(1);
  const [newData, setNewData] = useState([]);
  const [filterStatus, setFilterStatus] = useState(FILTER_DATA);
  const [file, setFile] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get('status');

  // const onFileChange = (e) => {
  //   const { files } = e.target;
  //   if (files.length === null) return;

  //   const selectedFile = files[0];
  //   setFile(selectedFile);

  //   e.target.value = null;
  // };

  const detailhandler = (e) => {
    const id = e.target.parentElement.parentElement.dataset.key;

    navigate(`/refund-dana/${id}`);
  };

  const exportHandler = async () => {
    try {
      await dispatch(getRefundReports({ status: status?.split(',') })).unwrap();
    } catch (err) {
      showToast({ type: 'error', message: 'Gagal download deposit reports!' });
    }
  };

  const importHandler = (selectedFile) => {
    const formData = new FormData();
    formData.append('file', selectedFile);

    setShowConfirmation({
      message: 'Apakah anda yakin ingin melakukan import Refund reports?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(importRefundReports(formData)).unwrap();
          showToast({ type: 'success', message: 'Berhasil melakukan import Refund reports' });
        } catch (err) {
          showToast({ type: 'error', message: 'Gagal melakukan import Refund reports' });
        } finally {
          dispatch(
            getAllRefund({
              page: currentPage,
              order: 'DESC',
              status: status === 'ALL' ? undefined : status?.split(','),
              limit: 10,
            }),
          );
          setFile(null);
        }
      },
    });
  };

  useEffect(() => {
    setSearchParams({ status: 'ALL' });
  }, []);

  useEffect(() => {
    dispatch(getAllRefund({ page: currentPage, limit: 10, status: status === 'ALL' ? undefined : status?.split(',') }));
  }, [currentPage]);

  useEffect(() => {
    if (!refundData || checkEmptyObject(refundData) || !refundData?.data) return;

    const newRefundData = refundData.data.map((item) => ({
      ...item,
      status: `refund-${item.status.toLowerCase()}`,
      refund_amount: setCurrency(item.refund_amount).replace('Rp', 'IDR'),
    }));

    setNewData(newRefundData);
  }, [refundData]);

  useEffect(() => {
    if (status?.toLowerCase() !== 'all') {
      setFilterStatus((prev) =>
        prev.map((item) =>
          status?.split(',').find((status) => status === item.id)
            ? { ...item, value: true }
            : { ...item, value: false },
        ),
      );
    } else if (status?.toLowerCase() == 'all') {
      setFilterStatus(FILTER_DATA);
    }
  }, [status]);

  useEffect(() => {
    if (status === 'ALL') {
      dispatch(getAllRefund({ limit: 10, page: 1 }));
      setNewData([]);
    } else {
      dispatch(getAllRefund({ limit: 10, page: 1, status: status?.split(',') }));
      setNewData([]);
    }
  }, [status]);

  useEffect(() => {
    if (file === null || file === undefined) return;
    importHandler(file);
  }, [file]);

  return (
    <TableWrapper
      icon={<RefundIcon fill="#009EF7" width="25px" height="25px" />}
      title="Refund Dana "
      CustomFilterComponent={true}
      style={{ minHeight: '700px' }}
      CustomSortComponent={
        <FilterTransaction
          selected={filterStatus}
          setPage={setCurrentPage}
          className="filter-status"
          placeholder="Filter Status"
          params={status}
          paramName="status"
        />
      }
      // importFromExcel={
      //   <>
      //     <input
      //       type="file"
      //       name="upload-excel-file"
      //       id="upload-excel-file"
      //       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      //       hidden
      //       ref={uploadRef}
      //       onChange={onFileChange}
      //     />
      //     <Button
      //       style={{
      //         background: '#F1A33A',
      //       }}
      //       height={30}
      //       onClick={() => uploadRef.current.click()}
      //     >
      //       <UploadIcon className="upload-icon" />
      //       Import From Excel
      //     </Button>
      //   </>
      // }
      exportToExcel={
        <Button
          style={{
            background: '#F1A33A',
          }}
          height={30}
          onClick={exportHandler}
        >
          <DownloadIcon className="download-icon" />
          Export To Excel
        </Button>
      }
    >
      {refundDataLoading ? (
        <LoadingSpinner />
      ) : !refundData.data || refundData.data.length === 0 ? (
        <EmptyState />
      ) : (
        <>
          <Table column={column} actionBtn onDetail={detailhandler} data={newData} currentPage={currentPage} />
          <PaginationTable
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            totalCount={refundData?.pagination?.total}
          />
        </>
      )}
    </TableWrapper>
  );
};

export default RefundComponent;
