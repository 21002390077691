import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAppContext } from 'components/Context/AppContext';
import { getAllDriversByFilter } from 'features/drivers/actions';
import { fetchOrderByIdWithApproval } from 'features/orders/actions';
import { createPublishTask } from 'features/publish-task/actions';
import { getDetailOrder } from 'features/orders/detailOrderSlice';
// import { resetSelected } from 'features/drivers/driverTaskSlice';
import Modals from '../Modals';
import { Button, InputField } from 'components/Global';
import CheckboxField from 'components/Global/CheckboxField';
import { ReactComponent as VehicleIcon } from 'icons/car-blue-bg.svg';
import { ReactComponent as SearchIcon } from 'icons/search-icon-black.svg';
import { checkEmptyObject, setCurrency } from 'utils/functionality';
import SelectDriverOptions from 'components/Global/SelectDriverOptions';
import { format, parseISO } from 'date-fns';
import { id } from 'date-fns/locale';
import { getConvertCurrency } from 'features/convert-currency/actions';

const TASK_TYPE = [
  {
    id: '1',
    task_type: 'RETURN',
    label: 'Ambil Mobil',
    checked: false,
  },
  {
    id: '2',
    task_type: 'DELIVERY',
    label: 'Antar Mobil',
    checked: false,
  },
];

const getErrorMessage = (message) => {
  if (message === "can't pick task due to date range collision")
    return 'Gagal Melakukan Publish. Jadwal Driver Bertabrakan';

  if (message === 'task has been created') return 'Task Sudah Dipublish';

  return 'Terjadi Kesalahan!';
};

const BroadcastTaskModal = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { setShowBroadcastTask, showToast, setShowConfirmation } = useAppContext();

  const { data: driversData } = useSelector((state) => state.drivers);
  // const { selected: selectedData } = useSelector((state) => state.driverTaskList);
  const orderData = useSelector(getDetailOrder);
  const { data: calculateFeeTaskResult } = useSelector((state) => state.calculateFeeTask);
  const { data: convertCurrencyData } = useSelector((state) => state.convertCurrency);

  // const [feeTask, setFeeTask] = useState('');
  const [isToAllDriver, setIsToAllDriver] = useState(true);
  const [taskType, setTaskType] = useState(TASK_TYPE);

  const [selectedDriver, setSelectedDriver] = useState(undefined);

  const [inputValueDrivers, setInputValueDrivers] = useState('');

  // FUNCTION TO PREVENT SCROLLING ON INPUT NUMBER TYPE
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  // const changeFeeHandler = (e) => {
  //   localStorage.setItem('fee-task', e.target.value);
  //   setFeeTask(e.target.value);
  // };

  const checkTaskTypeHandler = (e, item) => {
    const newTaskType = taskType.map((task) => {
      if (task.id === item.id) {
        return {
          ...task,
          checked: e.target.checked,
        };
      }

      return {
        ...task,
      };
    });

    setTaskType(newTaskType);
  };

  const gotFeeCurrency = (fee, currency) => {
    const RightFee =
      fee && currency === 'IDR'
        ? setCurrency(fee).replace('Rp', `${currency}`)
        : fee && currency === 'SGD'
        ? `${currency} ${fee.toFixed(2)}`
        : fee === 0
        ? `${currency} 0`
        : '-';
    return RightFee;
  };

  const submitHandler = async () => {
    // const validFee = feeTask !== '' && feeTask !== 0 && feeTask !== undefined;

    if (orderData?.order_detail?.without_driver) {
      if (taskType.every((item) => item.checked === false)) {
        return showToast({ type: 'error', message: 'Pilih Jenis Task' });
      }
    } else if (!selectedDriver) {
      if (!isToAllDriver) {
        return showToast({ type: 'error', message: 'Pilih Driver' });
      }
    }

    const pathName = location.pathname.split('/');

    let payload = {
      key: pathName[pathName.length - 1],
      // fee_per_task: +feeTask,
      driver_id: isToAllDriver ? undefined : selectedDriver?.id,
    };

    if (orderData?.order_detail?.without_driver) {
      for (let child of taskType) {
        if (child.checked === false) {
          continue;
        } else {
          if (child.task_type === 'RETURN') {
            payload = {
              return_task: true,
              ...payload,
            };
          } else {
            payload = {
              deliver_task: true,
              ...payload,
            };
          }
        }
      }
    }

    setShowConfirmation({
      show: true,
      message: 'Apakah anda ingin publish task order ini?',
      onClick: async () => {
        try {
          await dispatch(createPublishTask(payload)).unwrap();
          setShowBroadcastTask(false);
          dispatch(fetchOrderByIdWithApproval(pathName[pathName.length - 1]));
          showToast({ type: 'success', message: 'Berhasil publish task' });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          showToast({
            type: 'error',
            // message:
            //   error?.message === "can't pick task due to date range collision"
            //     ? 'Gagal Melakukan Publish. Jadwal Driver Bertabrakan'
            //     : error?.message,
            message: getErrorMessage(error?.slug),
          });
        }
      },
    });
  };

  useEffect(() => {
    if (
      !calculateFeeTaskResult ||
      checkEmptyObject(calculateFeeTaskResult) ||
      !orderData ||
      checkEmptyObject(orderData)
    )
      return;

    dispatch(
      getConvertCurrency({
        fromCurrency: calculateFeeTaskResult.to_currency,
        toCurrency: calculateFeeTaskResult.from_currency,
        amount: 1,
        date: format(parseISO(orderData?.created_at), 'yyyy-MM-dd', { locale: id }),
      }),
    );
  }, [calculateFeeTaskResult, orderData]);

  useEffect(() => {
    if (!orderData || checkEmptyObject(orderData) || orderData.order_driver_tasks.length === 0) return;

    let newMappingTaskType = [];

    for (let child of taskType) {
      if (orderData.order_driver_tasks.find((item) => item.task_type === child.task_type && item.string !== 'CANCEL')) {
        continue;
      } else {
        newMappingTaskType.push({ ...child });
      }
    }

    setTaskType(newMappingTaskType);
  }, [orderData]);

  useEffect(() => {
    if (!inputValueDrivers) {
      dispatch(
        getAllDriversByFilter({
          page: 0,
          limit: 0,
          locationId: orderData?.order_detail?.location_id,
          isDeactivated: false,
          packageId: orderData?.order_detail?.package_id,
        }),
      );
    } else {
      const timeout = setTimeout(() => {
        dispatch(
          getAllDriversByFilter({
            page: 0,
            limit: 0,
            search: inputValueDrivers,
            locationId: orderData?.order_detail?.location_id,
            isDeactivated: false,
            packageId: orderData?.order_detail?.package_id,
          }),
        );
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [inputValueDrivers]);

  return (
    <Modals
      setState={setShowBroadcastTask}
      icon={<VehicleIcon fill="#009EF7" width="25px" height="25px" />}
      title={'Broadcast Task Driver'}
    >
      <div className="broadcast-task-modal">
        <h2>{isToAllDriver ? 'Broadcast Task ke seluruh Driver Get&Ride' : 'Broadcast Task'}</h2>
        <form>
          <InputField
            label="Nilai Mata Uang"
            htmlFor="curse-currency"
            placeholder="0"
            value={
              convertCurrencyData && !checkEmptyObject(convertCurrencyData)
                ? `${gotFeeCurrency(convertCurrencyData.amount, convertCurrencyData.from).replace(
                    `${convertCurrencyData.from}`,
                    '',
                  )} ${convertCurrencyData.from} = ${convertCurrencyData.to}${gotFeeCurrency(
                    convertCurrencyData.converted_amount,
                    convertCurrencyData.to,
                  ).replace(`${convertCurrencyData.to}`, '')} `
                : ''
            }
            className="curse-currency"
            disable
          />

          <InputField
            label="Fee Driver"
            htmlFor="fee-per-task"
            placeholder="0"
            value={
              calculateFeeTaskResult?.fee_driver
                ? gotFeeCurrency(calculateFeeTaskResult?.fee_driver, calculateFeeTaskResult?.to_currency)
                : ''
            }
            onWheel={numberInputOnWheelPreventChange}
            disable
          />

          <InputField
            label="Tanggal Pembuatan Order"
            htmlFor="create-date"
            placeholder="Tanggal Pembuatan Order"
            value={orderData?.created_at ? format(parseISO(orderData?.created_at), 'dd MMMM yyyy', { locale: id }) : ''}
            className="date-create"
            disable
          />

          <div className="drivers-wrapper">
            <SelectDriverOptions
              label="Kirim Task"
              className="driver-list"
              htmlFor="driver-list"
              icon={<SearchIcon />}
              iconPosition="end"
              data={driversData?.users}
              selectedDriver={selectedDriver}
              onSelectDriver={(driver) => {
                setSelectedDriver(driver);
                setInputValueDrivers('');
              }}
              value={inputValueDrivers}
              onChange={(e) => setInputValueDrivers(e.target.value)}
              disabled={isToAllDriver}
              placeholder="Pilih Driver"
            />

            <CheckboxField
              label="Kirim ke Semua Driver"
              checked={isToAllDriver}
              onChange={(e) => {
                setIsToAllDriver(e.target.checked);
                setSelectedDriver(undefined);
              }}
              className="checkbox"
            />
          </div>

          {orderData?.order_detail?.without_driver && (
            <div className="task-type">
              <h2>Jenis Task</h2>
              <div className="checkboxes">
                {taskType.map((item) => (
                  <CheckboxField
                    key={item.id}
                    label={item.label}
                    checked={item.checked}
                    onChange={(e) => checkTaskTypeHandler(e, item)}
                  />
                ))}
              </div>
            </div>
          )}

          <div className="action-buttons">
            <Button variant="outline" onClick={() => setShowBroadcastTask(false)}>
              Kembali
            </Button>
            <Button type="button" onClick={submitHandler}>
              Kirim
            </Button>
          </div>
        </form>
      </div>
    </Modals>
  );
};

export default BroadcastTaskModal;
