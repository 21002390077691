import { createSlice } from '@reduxjs/toolkit';
import { getAllInboxes, getInboxDetail, getUnreadMessage, getUnreadNotificationCount } from './actions';

const initialState = {
  data: {},
  selected: {},
  totalUnreadNotif: 0,
  totalUnreadMessage: 0,
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const notificationInbox = createSlice({
  name: 'notificationInbox',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllInboxes.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllInboxes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllInboxes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getInboxDetail.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.selected = {};
      })
      .addCase(getInboxDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getInboxDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selected = action.payload;
      })
      .addCase(getUnreadMessage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(getUnreadMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getUnreadMessage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.totalUnreadMessage = action.payload.total_unread ?? 0;
      })
      .addCase(getUnreadNotificationCount.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(getUnreadNotificationCount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getUnreadNotificationCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.totalUnreadNotif = action.payload.total_unread ?? 0;
      });
  },
});

export default notificationInbox.reducer;
