import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getConvertCurrency = createAsyncThunk(
  'convertCurrency/getConvertCurrency',
  async ({ fromCurrency, toCurrency, amount, date }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.get(
        `${BASE_URL}/${API_VERSION}/convert-currency?from=${fromCurrency}&to=${toCurrency}&amount=${amount}&date=${date}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
      const data = await response.data;
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);
