import React from 'react';
import Button from 'components/Global/Button';
import { addDays, format } from 'date-fns';
import { ReactComponent as DateIcon } from '../../../../icons/calendar-icon.svg';
import { ReactComponent as ChevronDown } from '../../../../icons/chevron-down.svg';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import SelectFieldZone from 'components/Global/SelectfieldZone';
import { useAppContext } from 'components/Context/AppContext';
import { setCurrency } from 'utils/functionality';
import { useSelector } from 'react-redux';
import InputField from 'components/Global/InputField';
import { getLocationTimezone } from 'utils/getTimezone';
import SelectFieldShuttleZone from 'components/Global/SelectFieldShuttleZone';
import CheckboxField from 'components/Global/CheckboxField';

// BOOKING ZONE INITIAL STATE
const initialBookingZones = {
  date: '',
  pickup_zone_id: 0,
  detail_pickup_location: '',
  pickup_zone_price: 0,
  drop_off_zone_id: 0,
  detail_drop_off_location: '',
  drop_off_zone_price: 0,
  driving_zone_id: 0,
  driving_zone_price: 0,
  booking_start_time: '',
  booking_end_time: '',
  overtime_duration: 0,
  total_price: 0,
  pickup_list_zone_id: 0,
  drop_off_list_zone_id: 0,
  driving_list_zone_id: 0,
  detail_driving_location: '',
  is_driver_stay_overnight: false,
  driver_stay_overnight_price: 0,
};

const ZoneListForm = ({
  idx,
  item,
  handleOpen,
  // orderData,
  // setOrderData,
  startDate,
  accordionNumberOpen,
  setAccordionNumberOpen,
  durationRentDate,
  allBookingZone,
  setAllBookingZone,
  // selectedCar,
  // isFirstLoad,
  isOrderConfirmation,
  // allZonesList,
  zoneData,
  forCustomOrder,
  locTimeId,
  dayDuration,
  isFirstLoad,
  currency = 'IDR',
}) => {
  const formRef = useRef(null);

  const { showToast } = useAppContext();

  const { data: summaryOrderData } = useSelector((state) => state.summaryOrder);
  const { zoneList } = useSelector((state) => state.zone);

  const [bookingZoneData, setBookingZoneData] = useState(initialBookingZones);
  const [pickupLocation, setPickupLocation] = useState('');
  const [dropoffLocation, setDropoffLocation] = useState('');
  const [drivingLocation, setDrivingLocation] = useState('');
  const [highestZone, setHighestZone] = useState('');
  const [totalPriceZone, setTotalPriceZone] = useState(0);

  const [isSaved, setIsSaved] = useState(false);

  const handlePickupLocation = (zone) => {
    setPickupLocation(`${zone.name} (${zone.name_zone})`);

    setBookingZoneData({
      ...bookingZoneData,
      pickup_zone_id: zone.zone_id,
      pickup_list_zone_id: zone.id,
      detail_pickup_location: `${zone.name} (${zone.name_zone})`,
    });
    setIsSaved(false);
  };

  const handleDropoffLocation = (zone) => {
    setDropoffLocation(`${zone.name} (${zone.name_zone})`);

    setBookingZoneData({
      ...bookingZoneData,
      drop_off_zone_id: zone.zone_id,
      drop_off_list_zone_id: zone.id,
      detail_drop_off_location: `${zone.name} (${zone.name_zone})`,
    });
    setIsSaved(false);
  };

  const drivingZoneHandler = (e) => {
    setDrivingLocation(e.target.value);
    const drivingZoneId = zoneData?.find((item) => item.name === e.target.value)?.id;

    setBookingZoneData({
      ...bookingZoneData,
      driving_zone_id: drivingZoneId,
      detail_driving_location: e.target.value,
      driving_list_zone_id: 0,
    });
    setIsSaved(false);
  };

  const checkAcomodationHandler = (e) => {
    const checked = e.target.checked;
    setBookingZoneData({
      ...bookingZoneData,
      is_driver_stay_overnight: checked,
    });
    setIsSaved(false);
  };

  // SAVE BOOKING ZONE DATA
  const saveBookingZoneData = () => {
    // validation
    const validPickUpLocation = bookingZoneData.pickup_zone_id;
    const validDropoffLocation = bookingZoneData.drop_off_zone_id;
    const validZoneArea = bookingZoneData.driving_zone_id;
    const validStartBookingTime = dayDuration[idx]?.booking_start_time;
    const validEndBookingTime = dayDuration[idx]?.booking_end_time;

    if (!validPickUpLocation && item === 1) {
      return showToast({ type: 'error', message: 'Pilih Lokasi Penjemputan' });
    } else if (!validDropoffLocation && item === durationRentDate) {
      return showToast({ type: 'error', message: 'Pilih Lokasi Pengantaran' });
    } else if (!validZoneArea) {
      return showToast({ type: 'error', message: 'Pilih Area Penyewaan' });
    } else if (!validStartBookingTime) {
      return showToast({ type: 'error', message: 'Pilih Tanggal Mulai' });
    } else if (!validEndBookingTime) {
      return showToast({ type: 'error', message: 'Pilih Tanggal Selesai' });
    }

    const copyArrAllBookingZoneData = [...allBookingZone];
    copyArrAllBookingZoneData[idx] = {
      ...bookingZoneData,
      date: format(addDays(startDate, idx), 'yyyy-MM-dd'),
      booking_start_time: dayDuration[idx]?.booking_start_time,
      booking_end_time: dayDuration[idx]?.booking_end_time,
      overtime_duration: dayDuration[idx]?.overtime_duration || 0,
    };

    const zonePickup = zoneList?.list_zones?.find(
      (zone) => `${zone.name} (${zone.name_zone})` === pickupLocation,
    )?.name_zone;
    const zoneDropoff = zoneList?.list_zones?.find(
      (zone) => `${zone.name} (${zone.name_zone})` === dropoffLocation,
    )?.name_zone;

    setHighestZone(
      [zonePickup, zoneDropoff, drivingLocation].sort((a, b) => +a.charAt(a.length - 1) - +b.charAt(b.length - 1))[2],
    );
    setAllBookingZone(copyArrAllBookingZoneData);
    setIsSaved(true);

    if (item === durationRentDate) {
      setAccordionNumberOpen(0);
    } else {
      setAccordionNumberOpen(item + 1);
    }
  };

  // SET BOOKING ZONE DATA WHEN THE DATA HAS BEEN ENTERED BEFORE
  useEffect(() => {
    if (!isFirstLoad) return;
    if (!zoneData || !zoneData.length) return;
    if (!allBookingZone || allBookingZone.length === 0) return;

    if (allBookingZone.length >= item) {
      setBookingZoneData({
        booking_end_time: allBookingZone[idx].booking_end_time,
        booking_start_time: allBookingZone[idx].booking_start_time,
        date: allBookingZone[idx].date,
        pickup_zone_id: allBookingZone[idx].pickup_zone_id,
        detail_pickup_location: allBookingZone[idx].detail_pickup_location,
        pickup_zone_price: allBookingZone[idx].pickup_zone_price,
        drop_off_zone_id: allBookingZone[idx].drop_off_zone_id,
        detail_drop_off_location: allBookingZone[idx].detail_drop_off_location,
        drop_off_zone_price: allBookingZone[idx].drop_off_zone_price,
        driving_zone_id: allBookingZone[idx].driving_zone_id,
        driving_zone_price: allBookingZone[idx].driving_zone_price,
        overtime_duration: allBookingZone[idx].overtime_duration,
        pickup_list_zone_id: allBookingZone[idx].pickup_list_zone_id,
        drop_off_list_zone_id: allBookingZone[idx].drop_off_list_zone_id,
        driving_list_zone_id: allBookingZone[idx].driving_list_zone_id,
        detail_driving_location: allBookingZone[idx].detail_driving_location,
        is_driver_stay_overnight: allBookingZone[idx].is_driver_stay_overnight,
        driver_stay_overnight_price: allBookingZone[idx].driver_stay_overnight_price,
        total_price:
          Object.keys(summaryOrderData).length !== 0 && summaryOrderData.order_zone_price?.length > 0
            ? summaryOrderData?.order_zone_price[idx]?.total_price
            : allBookingZone[idx].total_price,
      });

      let bookingPickupLocation;
      let bookingDropoffLocation;
      let bookingDrivingLocation = zoneData?.find((item) => item.id === allBookingZone[idx].driving_zone_id)?.name;

      if (!allBookingZone[idx].detail_pickup_location) {
        setPickupLocation(zoneData?.find((item) => item.id == allBookingZone[idx]?.pickup_zone_id)?.name);
        bookingPickupLocation = zoneData?.find((item) => item.id == allBookingZone[idx]?.pickup_zone_id)?.name;
      } else {
        setPickupLocation(allBookingZone[idx]?.detail_pickup_location);
        bookingPickupLocation =
          allBookingZone[idx]?.detail_pickup_location?.match(/\((.*?)\)/)?.length > 0
            ? allBookingZone[idx]?.detail_pickup_location?.match(/\((.*?)\)/)[1]
            : '';
      }

      if (!allBookingZone[idx].detail_drop_off_location) {
        setDropoffLocation(zoneData?.find((item) => item.id == allBookingZone[idx]?.drop_off_zone_id)?.name);
        bookingDropoffLocation = zoneData?.find((item) => item.id == allBookingZone[idx]?.drop_off_zone_id)?.name;
      } else {
        setDropoffLocation(allBookingZone[idx]?.detail_drop_off_location);
        bookingDropoffLocation =
          allBookingZone[idx]?.detail_drop_off_location?.match(/\((.*?)\)/)?.length > 0
            ? allBookingZone[idx]?.detail_drop_off_location?.match(/\((.*?)\)/)[1]
            : '';
      }

      setDrivingLocation(bookingDrivingLocation);

      setHighestZone(
        [bookingPickupLocation, bookingDropoffLocation, bookingDrivingLocation].sort(
          (a, b) => +a.charAt(a.length - 1) - +b.charAt(b.length - 1),
        )[2],
      );

      if (allBookingZone[idx].is_driver_stay_overnight) {
        const totalPrice =
          Object.keys(summaryOrderData).length !== 0
            ? summaryOrderData?.order_zone_price[idx]?.total_price
            : allBookingZone[idx].total_price;
        const acomodationDriverPrice =
          Object.keys(summaryOrderData).length !== 0
            ? summaryOrderData?.order_zone_price[idx]?.driver_stay_overnight_price
            : allBookingZone[idx].driver_stay_overnight_price;
        setTotalPriceZone(totalPrice + acomodationDriverPrice);
      } else {
        setTotalPriceZone(
          Object.keys(summaryOrderData).length !== 0 && summaryOrderData.order_zone_price?.length > 0
            ? summaryOrderData?.order_zone_price[idx]?.total_price
            : allBookingZone[idx].total_price,
        );
      }

      setIsSaved(true);
    }
  }, [allBookingZone, zoneData, durationRentDate, isFirstLoad]);

  // SET TOTAL PRICE ZONE
  useEffect(() => {
    if (!Object.keys(summaryOrderData).length) return;
    if (!summaryOrderData.order_zone_price?.length) return;

    const totalPrice = summaryOrderData?.order_zone_price[idx]?.total_price;
    const acomodationDriverPrice = summaryOrderData?.order_zone_price[idx]?.driver_stay_overnight_price;

    setTotalPriceZone(totalPrice + acomodationDriverPrice);
  }, [summaryOrderData]);

  const timezone = !locTimeId ? '' : getLocationTimezone(locTimeId);

  useEffect(() => {
    if (isFirstLoad) return;

    setBookingZoneData({
      booking_end_time: allBookingZone[idx]?.booking_end_time,
      booking_start_time: allBookingZone[idx]?.booking_start_time,
      date: allBookingZone[idx]?.date,
      pickup_zone_id: 0,
      detail_pickup_location: '',
      pickup_zone_price: 0,
      drop_off_zone_id: 0,
      detail_drop_off_location: '',
      drop_off_zone_price: 0,
      driving_zone_id: 0,
      driving_zone_price: 0,
      overtime_duration: 0,
      pickup_list_zone_id: 0,
      drop_off_list_zone_id: 0,
      driving_list_zone_id: 0,
      detail_driving_location: '',
      is_driver_stay_overnight: false,
      driver_stay_overnight_price: 0,
      total_price: 0,
    });

    setHighestZone('');
    setPickupLocation('');
    setDropoffLocation('');
    setDrivingLocation('');
    setTotalPriceZone(0);
    setIsSaved(false);
  }, [durationRentDate]);

  return (
    <li className="list-booking-zone">
      {/* HEADER LIST */}
      <div className="header" onClick={() => handleOpen(item)}>
        <div className="header__date">
          <div>
            <DateIcon />
            <span>Day-{item}</span>
          </div>
          <div>
            <span />
            <p>{!isNaN(startDate) && format(addDays(startDate, idx), 'dd MMMM yyyy')}</p>
          </div>
          <div
            className={`selected-zone ${
              highestZone == 'Zona 0' ? 'zone-zero' : highestZone == 'Zona 1' ? 'zone-one' : 'zone-two'
            }`}
            style={{ display: isOrderConfirmation ? 'block' : isSaved ? 'block' : 'none' }}
          >
            {highestZone}
          </div>
          <div
            className="selected-zone-price"
            style={{ display: isOrderConfirmation ? 'block' : isSaved ? 'block' : 'none' }}
          >
            <span>{`(${setCurrency(totalPriceZone || 0, currency)})`}</span>
          </div>
        </div>
        <ChevronDown className={`chevron ${accordionNumberOpen === item ? 'up' : 'down'}`} />
      </div>

      {/* BODY LIST */}
      <div className="form-booking-zone" style={{ height: accordionNumberOpen === item ? 400 : 0 }}>
        <div ref={formRef} className="form-booking-zone__wrapper">
          {/* INPUT FORM */}
          <div className="form-booking-zone__input-data">
            <SelectFieldShuttleZone
              label="PickUp"
              id={`pickup-${item}`}
              value={pickupLocation}
              onChange={handlePickupLocation}
              placeholder="Pilih Zona Pick Up"
              disable={isOrderConfirmation}
            />

            <SelectFieldShuttleZone
              label="Drop Off"
              id={`dropoff-${item}`}
              value={dropoffLocation}
              onChange={handleDropoffLocation}
              placeholder="Pilih Zona Drop Off"
              disable={isOrderConfirmation}
            />

            {/* TAMBAH AREA PENYEWAAN */}
            <SelectFieldZone
              label="Tambah Area Penyewaan"
              id={`area-penyewaan-tambahan-${item}`}
              placeholder="Pilih Area Penyewaan"
              value={drivingLocation}
              onChange={drivingZoneHandler}
              disable={isOrderConfirmation}
            />

            {!forCustomOrder ? (
              <>
                <div className="form-booking-zone__time">
                  <InputField
                    label="Jam Mulai"
                    value={(bookingZoneData?.booking_start_time || '') + ' ' + timezone}
                    disable={isOrderConfirmation}
                    readOnly
                  />
                  <InputField
                    label="Jam Selesai"
                    value={(bookingZoneData?.booking_end_time || '') + ' ' + timezone}
                    disable={isOrderConfirmation}
                    readOnly
                  />
                </div>
                <InputField
                  label="Overtime"
                  value={
                    bookingZoneData?.overtime_duration > 0
                      ? bookingZoneData?.overtime_duration
                      : 'Tidak Ada Overtime' || ''
                  }
                  disable={isOrderConfirmation}
                  readOnly
                />
              </>
            ) : null}

            {/* DRIVER ACOMODATION */}
            {item < durationRentDate && (
              <div className="form-booking-zone__input-data__driver-acomodation">
                <div className="check">
                  <CheckboxField
                    htmlfor={`checkbox-acomodation$-${item}`}
                    name={`checkbox-acomodation$-${item}`}
                    checked={bookingZoneData.is_driver_stay_overnight}
                    onChange={checkAcomodationHandler}
                    readOnly
                    disable={isOrderConfirmation}
                  />
                </div>
                <div className="description">
                  <h5>Akomodasi Driver</h5>
                  <p>
                    Customer akan dikenakan biaya tambahan untuk akomodasi driver (Penginapan) untuk sewa lebih dari
                    sehari, Untuk biaya tambahan yang dikenakan sebesar <span>{setCurrency(150000, currency)}</span> /
                    Hari
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* BUTTON */}
          <div className="form-booking-zone__action-button">
            {isOrderConfirmation ? null : (
              <Button
                type="button"
                variant="primary"
                style={{
                  background: '#F1A33A',
                  width: '104px',
                }}
                onClick={saveBookingZoneData}
                disabled={isSaved}
              >
                Simpan
              </Button>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default ZoneListForm;
