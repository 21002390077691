import React, { useState } from 'react';
import ZoneListForm from './ZoneListForm';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllZone, getListZone } from 'features/zone/actions';
import { getAllCategories } from 'features/category/actions';
import { fetchSummaryOrder } from 'features/orders/actions';
import { useAppContext } from 'components/Context/AppContext';
import { overtimeWithDriver } from 'components/HomeComponent/Content/CustomOrderComponent/DetailSewa/WithDriverForm';

const BookingZone = ({
  startDate,
  durationRentDate,
  orderData,
  setOrderData,
  selectedCar,
  isOrderConfirmation,
  dayDuration,
  // allZonesList,
  forCustomOrder,
  locTimeId,
  rentalLocationId,
}) => {
  const dispatch = useDispatch();

  const { data: zoneData } = useSelector((state) => state.zone);
  const { showToast } = useAppContext();

  const [allBookingZone, setAllBookingZone] = useState([]);
  const [accordionNumberOpen, setAccordionNumberOpen] = useState(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  // FUNCTION OPEN THE ACCORDION
  const handleOpen = (item) => {
    setIsFirstLoad(false);
    if (item === accordionNumberOpen || item > durationRentDate) {
      setAccordionNumberOpen(0);
    } else {
      setAccordionNumberOpen(item);
    }
  };

  // CALL ZONE DATA
  useEffect(() => {
    // dispatch(getListZone({ page: 1 }));
    dispatch(getAllCategories({ page: 1 }));
  }, []);

  // GET DELIVERY LOCATION WHEN LOCATION HAS BEEN CHOOSE
  useEffect(() => {
    if (!rentalLocationId || !selectedCar?.id) return;

    dispatch(
      getAllZone({ locationId: rentalLocationId?.id || rentalLocationId, categoryId: selectedCar?.category.id }),
    );
    dispatch(
      getListZone({
        all: true,
        locationId: rentalLocationId?.id || rentalLocationId,
        categoryId: selectedCar?.category.id,
      }),
    );
  }, [rentalLocationId, selectedCar]);

  // SET BOOKING ZONE
  useEffect(() => {
    if (!allBookingZone || allBookingZone.length === 0) return;
    if (!zoneData || zoneData.length === 0) return;
    if (isOrderConfirmation) return;

    const copyOrderData = { ...orderData };

    if (durationRentDate < allBookingZone.length) {
      const slicedBookingZone = allBookingZone.slice(0, durationRentDate);
      copyOrderData.order_detail.booking_zones = slicedBookingZone;
    } else {
      copyOrderData.order_detail.booking_zones = allBookingZone;
    }

    // GET ORDER SUMMARY
    const payload = {
      end_booking_date: orderData.order_detail.end_booking_date,
      end_booking_time: orderData.order_detail.end_booking_time,
      order_type_id: orderData.order_type_id,
      start_booking_date: orderData.order_detail.start_booking_date,
      start_booking_time: orderData.order_detail.start_booking_time,
      vehicle_id: orderData.order_detail.vehicle_id,
      without_driver: orderData.order_detail.without_driver ? 1 : 0,
      deposit: orderData.deposit,
      violations: orderData.order_violations.reduce((acc, cur) => acc + cur.cost, 0),
      overtime: orderData.over_time,
      order_booking_zone: allBookingZone.map((item, idx) => ({
        day: idx + 1,
        pick_up_zone_id: item.pickup_zone_id,
        drop_off_zone_id: item.drop_off_zone_id,
        driving_zone_id: item.driving_zone_id,
        booking_start_time: dayDuration[idx]?.booking_start_time,
        booking_end_time: dayDuration[idx]?.booking_end_time,
        overtime_duration: overtimeWithDriver.find((ovt) => ovt.id === dayDuration[idx]?.overtime_duration)?.value || 0,
        is_driver_stay_overnight: item.is_driver_stay_overnight,
      })),
      pasengger_number: orderData.order_detail.passenger_number,
    };

    const getSummary = async () => {
      try {
        const response = await dispatch(fetchSummaryOrder(payload));
        if (response.meta?.requestStatus === 'fulfilled') {
          return;
        }
        showToast({ type: 'error', message: 'Terjadi Kesalahan' });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        showToast({ type: 'error', message: 'Terjadi Kesalahan' });
      }
    };

    getSummary();

    setOrderData(copyOrderData);
  }, [allBookingZone, durationRentDate]);

  // SET BOOKING ZONE ORDER DATA WHEN THE DATA HAS BEEN ENTERED BEFORE
  useEffect(() => {
    if (!isFirstLoad) return;
    if (!rentalLocationId && !isOrderConfirmation) return;
    if (!orderData.order_detail?.booking_zones) return;

    setAllBookingZone(orderData.order_detail.booking_zones);
  }, [orderData?.order_detail?.booking_zones, isFirstLoad, rentalLocationId, isOrderConfirmation]);

  return (
    <div className="booking-zone">
      {durationRentDate === 0 ? (
        <div className="booking-zone__empty-data">Lengkapi Data Detail Sewa Terlebih Dahulu</div>
      ) : !selectedCar ? (
        <div className="booking-zone__empty-data">Lengkapi Data Detail Sewa Terlebih Dahulu</div>
      ) : (
        <ul className="booking-zone__form">
          {Array.from({ length: durationRentDate }, (_, i) => i + 1).map((item, idx) => (
            <ZoneListForm
              key={idx}
              idx={idx}
              item={item}
              handleOpen={handleOpen}
              // orderData={orderData}
              // setOrderData={setOrderData}
              startDate={startDate}
              accordionNumberOpen={accordionNumberOpen}
              setAccordionNumberOpen={setAccordionNumberOpen}
              durationRentDate={durationRentDate}
              zoneData={zoneData}
              // zoneList={zoneList}
              allBookingZone={allBookingZone}
              setAllBookingZone={setAllBookingZone}
              selectedCar={selectedCar}
              // isFirstLoad={isFirstLoad}
              isOrderConfirmation={isOrderConfirmation}
              // allZonesList={allZonesList}
              forCustomOrder={forCustomOrder}
              locTimeId={locTimeId}
              rentalLocationId={rentalLocationId}
              dayDuration={dayDuration}
              isFirstLoad={isFirstLoad}
              // currency={rentalLocationId?.currency}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default BookingZone;
