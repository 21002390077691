import React, { useRef } from 'react';
import { Button, InputField } from 'components/Global';
import InputTelpField from 'components/Global/InputTelpField';
import UploadImageCustomOrder from 'components/Global/UploadImageCustomOrder';
import { useEffect } from 'react';
import { useAppContext } from 'components/Context/AppContext';
import { useSelector } from 'react-redux';
import SelectFieldShuttle from 'components/Global/SelectFieldShuttle';
import DetailSewa from '../DetailSewa';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { useLocation } from 'react-router-dom';

// UPLOAD IMAGE ARRAY
const uploadImageField = [
  {
    id: 'uif1',
    title: 'KTP',
    cheked: false,
  },
  {
    id: 'uif2',
    title: 'SIM',
    checked: false,
  },
];

// REGEX VALIDATION
const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const DetailPenyewa = ({
  nextPage,
  orderData,
  setOrderData,
  isOrderConfirmation,
  ktpImageLink,
  setKtpImageLink,
  simImageLink,
  setSimImageLink,
  selectedAdditionalItem,
  setSelectedAdditionalItem,
  vehicleInput,
  setVehicleInput,
  selectedCar,
  setSelectedCar,
  startRentDate,
  setStartRentDate,
  endRentDate,
  setEndRentDate,
  deliveryLocation,
  setDeliveryLocation,
  returnLocation,
  setReturnLocation,
  refferalCode,
  setRefferalCode,
  driver,
  setDriver,
  allVehicle,
  selectedOvertime,
  setSelectedOvertime,
  selectedPaymentType,
  setSelectedPaymentType,
  durationRent,
  setDurationRent,
  dayDuration,
  setDayDuration,
  allZonesList,
  isSwitch,
  setIsSwitch,
  rentalLocationId,
  setRentalLocationId,
  luggageAirport,
  setLuggageAirport,
  passengersAirport,
  setPassengersAirport,
  startLocation,
  setStartLocation,
  endLocation,
  setEndLocation,
  locationData,
  setLocationData,
}) => {
  const childRef = useRef(null);
  const provider = new OpenStreetMapProvider();
  const location = useLocation();

  const pathName = location.pathname.split('/').filter((item) => item !== '')[0];

  // CONTEXT
  const { showToast, setShowSpinner } = useAppContext();

  // GLOBAL STATE
  const { status: statusUploadImage } = useSelector((state) => state.createOrder);
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);

  // SCROLL TO TOP WHEN COMPONENT IS LOAD
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // UPLOAD IMAGE FUNCTION
  const uploadImageAction = (file, name, link) => {
    const lowerCaseName = name.toLocaleLowerCase();
    let identityObject;

    if (lowerCaseName === 'ktp') {
      setKtpImageLink(link);

      if (orderData.order_detail.identity === null) {
        identityObject = { [lowerCaseName]: file.file, sim: null };
      } else {
        identityObject = { ...orderData.order_detail.identity, [lowerCaseName]: file.file };
      }
    } else {
      setSimImageLink(link);
      if (orderData.order_detail.identity === null) {
        identityObject = { [lowerCaseName]: file.file, ktp: null };
      } else {
        identityObject = { ...orderData.order_detail.identity, [lowerCaseName]: file.file };
      }
    }

    setOrderData({
      ...orderData,
      order_detail: {
        ...orderData.order_detail,
        identity: identityObject,
      },
    });
  };

  // FUNCTION TO PREVENT SCROLLING ON INPUT NUMBER TYPE
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  // SHOW SPINNER WHEN UPLOAD IMAGE
  useEffect(() => {
    if (statusUploadImage === 'loading') {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [statusUploadImage]);

  // FUCNTION VALIDATION & TO THE NEXT PAGE
  const submitHandler = () => {
    // e.preventDefault();

    if (!rentalLocationId?.is_special_airport_transfer) {
      // some validation
      const validUsername = orderData?.user_name.replace(/\s/g, '').length !== 0;
      const validUserEmail = new RegExp(regexEmail).test(orderData?.email);
      const validUserWaNumber = orderData?.wa_number.replace(/s/g, '').length !== 0;
      // &&
      // orderData?.wa_number.replace(/s/g, '').length >= 10 &&
      // orderData?.wa_number.replace(/s/g, '').length < 14;
      // const validIdentity = orderData?.order_detail?.identity;
      const validKTP = orderData?.order_detail?.identity?.ktp;
      // const validSIM = orderData?.order_detail?.identity?.sim;

      // show toast if theres an error
      if (!rentalLocationId) {
        showToast({ type: 'error', message: 'Pilih Lokasi Rental' });
        return;
      } else if (!validUsername) {
        showToast({ type: 'error', message: 'Nama tidak valid' });
        return;
      } else if (!validUserEmail) {
        showToast({ type: 'error', message: 'Email tidak valid' });
        return;
      } else if (!validUserWaNumber) {
        showToast({ type: 'error', message: 'Nomor WA tidak valid' });
        return;
      } else if (!validKTP) {
        showToast({ type: 'error', message: 'Upload KTP' });
        return;
      }
    }

    if (childRef.current) {
      childRef.current.callGrandChild();
    }
  };

  // SEARCH LOCATION BY INPUT
  const searchFunc = async (input) => {
    const results = await provider
      .search({ query: input })
      .then((res) =>
        res?.length > 0
          ? setLocationData({ latlng: [res[0].y, res[0].x], bounds: res[0].bounds })
          : setLocationData({ latlng: [-8.2271303, 115.1919203], bounds: null }),
      )
      .catch(() => setLocationData({ latlng: [-8.2271303, 115.1919203], bounds: null }));
    return results;
  };

  const rentalLocationHandler = (location) => {
    setRentalLocationId(location);

    if (pathName === 'airport-transfer') {
      if (location.is_special_airport_transfer) {
        setOrderData({
          ...orderData,
          order_type_id: 7,
          order_detail: {
            ...orderData.order_detail,
            rental_delivery_location: '',
            rental_return_location: '',
            rental_delivery_location_detail: '',
            rental_return_location_detail: '',
          },
        });
        setStartLocation({
          name: '',
          lat: 0,
          lng: 0,
        });
        setEndLocation({
          name: '',
          lat: 0,
          lng: 0,
        });
        searchFunc(location.name);
        return;
      }

      setDeliveryLocation({});
      setReturnLocation({});
      setSelectedCar(undefined);
      setOrderData({
        ...orderData,
        airport_transfer_package_id: 0,
        order_detail: {
          ...orderData.order_detail,
          rental_delivery_location: '',
          rental_return_location: '',
          rental_delivery_location_detail: '',
          rental_return_location_detail: '',
        },
      });
    } else if (pathName === 'without-driver') {
      setSelectedAdditionalItem(undefined);
    } else if (pathName === 'with-driver') {
      if (location.id === rentalLocationId.id) return;
      setRentalLocationId(location);

      setSelectedAdditionalItem(undefined);
      setSelectedCar(undefined);
      setVehicleInput('');
      setOrderData({
        ...orderData,
        order_detail: {
          ...orderData.order_detail,
          vehicle_id: '',
          passenger_number: 0,
          booking_zones: orderData.order_detail.booking_zones
            ? orderData.order_detail.booking_zones.map((zone) => ({
                date: zone.date,
                pickup_zone_id: 0,
                detail_pickup_location: '',
                pickup_zone_price: 0,
                drop_off_zone_id: 0,
                detail_drop_off_location: '',
                drop_off_zone_price: 0,
                driving_zone_id: 0,
                driving_zone_price: 0,
                booking_start_time: zone.booking_start_time,
                booking_end_time: zone.booking_end_time,
                overtime_duration: zone.overtime_duration,
                total_price: 0,
                pickup_list_zone_id: 0,
                drop_off_list_zone_id: 0,
                driving_list_zone_id: 0,
                detail_driving_location: '',
                is_driver_stay_overnight: false,
                driver_stay_overnight_price: 0,
              }))
            : [],
        },
      });
    }
  };

  return (
    <form className="detail-penyewa">
      {/* DETAIL PENYEWA FORM */}
      <div className="detail-penyewa__detail-penyewa-form">
        <h2 className="detail-penyewa__detail-penyewa-form__title">Setting Custom Order</h2>
        <SelectFieldShuttle
          label="Lokasi"
          htmlFor="lokasi-rental"
          className="detail-penyewa__detail-penyewa-form__rental-location"
          data={rentalLocationData}
          value={rentalLocationId.id}
          onChange={rentalLocationHandler}
          placeholder="Pilih Lokasi"
          searchFeature
          disable={isOrderConfirmation}
        />

        {pathName === 'airport-transfer' && rentalLocationId?.is_special_airport_transfer ? null : (
          <>
            <h2 className="detail-penyewa__detail-penyewa-form__title">Detail Penyewa</h2>
            <div className="detail-penyewa__detail-penyewa-form__form">
              {/* input */}
              <div className="detail-penyewa__detail-penyewa-form__form__one">
                <InputField
                  type="text"
                  label="Nama Penyewa"
                  htmlFor="nama-penyewa"
                  placeholder="Tulis Nama Penyewa"
                  value={orderData.user_name}
                  onChange={(e) => setOrderData({ ...orderData, user_name: e.target.value })}
                  disable={isOrderConfirmation}
                />
                <InputField
                  type="text"
                  label="Email"
                  htmlFor="email-penyewa"
                  placeholder="Masukan Email"
                  value={orderData.email}
                  onChange={(e) => setOrderData({ ...orderData, email: e.target.value })}
                  disable={isOrderConfirmation}
                />
                <InputTelpField
                  label="No Telpon / Whatsapp"
                  htmlFor="telp-penyewa"
                  value={orderData.wa_number || ''}
                  onChange={(e) => setOrderData({ ...orderData, wa_number: e.target.value })}
                  disable={isOrderConfirmation}
                  countryCodeValue={orderData.phone_country_code}
                  onSelectCountry={(code) => setOrderData({ ...orderData, phone_country_code: code })}
                  onWheel={numberInputOnWheelPreventChange}
                />
              </div>
              {/* upload image */}
              <div className="detail-penyewa__detail-penyewa-form__form__two">
                {uploadImageField.map((item) => (
                  <div key={item.id}>
                    <div>
                      <h3>
                        {item.title} {item.title === 'SIM' && `(Opsional)`}
                      </h3>
                    </div>
                    {/* upload image field */}
                    <UploadImageCustomOrder
                      htmlFor={item.title}
                      uploadAction={uploadImageAction}
                      imageFile={item.title === 'KTP' ? ktpImageLink : simImageLink}
                      base64={true}
                      disable={isOrderConfirmation}
                    />
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

        <DetailSewa
          selectedAdditionalItem={selectedAdditionalItem}
          setSelectedAdditionalItem={setSelectedAdditionalItem}
          nextPage={nextPage}
          orderData={orderData}
          setOrderData={setOrderData}
          vehicleInput={vehicleInput}
          setVehicleInput={setVehicleInput}
          selectedCar={selectedCar}
          setSelectedCar={setSelectedCar}
          startRentDate={startRentDate}
          setStartRentDate={setStartRentDate}
          endRentDate={endRentDate}
          setEndRentDate={setEndRentDate}
          deliveryLocation={deliveryLocation}
          setDeliveryLocation={setDeliveryLocation}
          returnLocation={returnLocation}
          setReturnLocation={setReturnLocation}
          refferalCode={refferalCode}
          setRefferalCode={setRefferalCode}
          driver={driver}
          setDriver={setDriver}
          allVehicle={allVehicle}
          selectedOvertime={selectedOvertime}
          setSelectedOvertime={setSelectedOvertime}
          selectedPaymentType={selectedPaymentType}
          setSelectedPaymentType={setSelectedPaymentType}
          durationRent={durationRent}
          setDurationRent={setDurationRent}
          dayDuration={dayDuration}
          setDayDuration={setDayDuration}
          allZonesList={allZonesList}
          isSwitch={isSwitch}
          setIsSwitch={setIsSwitch}
          rentalLocationId={rentalLocationId}
          setRentalLocationId={setRentalLocationId}
          onSubmitDetailSewa={submitHandler}
          ref={childRef}
          luggageAirport={luggageAirport}
          setLuggageAirport={setLuggageAirport}
          passengersAirport={passengersAirport}
          setPassengersAirport={setPassengersAirport}
          startLocation={startLocation}
          setStartLocation={setStartLocation}
          endLocation={endLocation}
          setEndLocation={setEndLocation}
          locationData={locationData}
          setLocationData={setLocationData}
          isOrderConfirmation={isOrderConfirmation}
        />
      </div>

      {/* ACTION BUTTON */}
      {!isOrderConfirmation && (
        <div className="detail-penyewa__button-action">
          <Button type="button" width="165px" height="39px" size="md" className="button" onClick={submitHandler}>
            Lanjutkan
          </Button>
        </div>
      )}
    </form>
  );
};

export default DetailPenyewa;
