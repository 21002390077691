import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import { InputField, TableWrapper } from 'components/Global';
import InputTelpField from 'components/Global/InputTelpField';
import VehiclePhotos from '../VechiclePhotos';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { checkEmptyObject, setCurrency } from 'utils/functionality';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailDriver, getVehiclePackages } from 'features/drivers/actions';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import BlueMappin from 'icons/mappin-icon-blue.svg';
import YellowMappin from 'icons/mappin-icon-yellow.svg';
import { ReactComponent as ChevronDown } from 'icons/arrow-down-icon.svg';

import 'leaflet/dist/leaflet.css';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

const WithoutDriverTask = ({ data, customer, setShowImage, selectedDriverTaskData, airportTransfer, oneWay }) => {
  const dispatch = useDispatch();

  const customIconBlue = L.icon({
    iconUrl: BlueMappin,
    iconSize: [20, 20],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  });

  const customIconYellow = L.icon({
    iconUrl: YellowMappin,
    iconSize: [20, 20],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  });

  const { selected: selectedDriver, vehiclePackages } = useSelector((state) => state.drivers);

  const mappingPhotoData = (data, progres) => {
    if (!data) {
      return {};
    }

    const theItem = data.find((item) => item.progres === progres);

    return theItem;
  };

  const getVehiclePlate = (orderType) => {
    if (!orderType) return;

    if (orderType == '1' || orderType == '2') {
      return data?.order_detail?.vehicle?.license_number;
    } else if (orderType == '3' || orderType == '4') {
      return data?.partner_vehicle?.license_plate;
    } else if (orderType == '5' || orderType == '6') {
      return selectedDriver?.vehicle?.license_number;
    }
  };

  const getVehicleName = (orderType) => {
    if (!orderType) return;

    if (orderType == '1' || orderType == '2') {
      return `${data?.order_detail?.vehicle?.brand_name} ${data?.order_detail?.vehicle?.name}`;
    } else if (orderType == '3' || orderType == '4') {
      return `${data?.partner_vehicle?.name}`;
    } else if (orderType == '5' || orderType == '6') {
      return selectedDriver?.vehicle?.name;
    }
  };

  const gotRightCurrency = (fee, currency) => {
    const RightFee =
      fee && currency === 'IDR'
        ? setCurrency(fee).replace('Rp', `${currency}`)
        : fee && fee.toFixed(2) < 1 && currency === 'SGD'
        ? `${currency} ${fee.toFixed(2)}`
        : '-';
    return RightFee;
  };

  useEffect(() => {
    if (!selectedDriverTaskData || !selectedDriverTaskData?.driver_id) return;

    dispatch(getDetailDriver(selectedDriverTaskData.driver_id));
  }, [selectedDriverTaskData]);

  useEffect(() => {
    if (!selectedDriver || checkEmptyObject(selectedDriver)) return;

    const locationId = selectedDriver?.user_location?.location_id;
    dispatch(getVehiclePackages(locationId));
  }, [selectedDriver]);

  return (
    <>
      <TableWrapper icon={<CarIcon fill="#009EF7" width="25px" height="25px" />} title="Driver Task">
        <h2>Detail Penyewa</h2>
        <div className="detail-driver-task__order-detail">
          <InputField label="Nama Lengkap" disable readOnly value={data?.user_name || '-'} />

          <InputField label="No Order" disable readOnly value={data?.order_key || '-'} />

          <InputTelpField
            label="No Handphone"
            countryCodeValue={data?.phone_country_code || '+62'}
            disable
            readOnly
            value={data?.wa_number || '-'}
          />

          <InputField label="Plat Nomer" disable readOnly value={getVehiclePlate(data?.order_type_id) || '-'} />

          <InputField label="Jumlah Penumpang" disable readOnly value={data?.order_detail?.passenger_number || '0'} />

          <InputField label="Mobil" disable readOnly value={getVehicleName(data?.order_type_id)} />
        </div>

        <h2>Detail Sewa</h2>
        <div className="detail-driver-task__order-detail">
          <InputField
            label={airportTransfer ? 'Lokasi Penjemputan' : 'Lokasi Pengantaran'}
            disable
            readOnly
            value={data?.order_detail?.rental_delivery_location || '-'}
          />

          <InputField
            label={airportTransfer ? 'Lokasi Pengantaran' : oneWay ? 'Lokasi Tempat Tujuan' : 'Lokasi Pengembalian'}
            disable
            readOnly
            value={data?.order_detail?.rental_return_location || '-'}
          />

          {(selectedDriverTaskData?.order?.order_type_id == 5 || selectedDriverTaskData?.order?.order_type_id == 6) &&
            selectedDriverTaskData?.order?.order_detail?.origin && (
              <MapContainer
                center={[
                  selectedDriverTaskData?.order?.order_detail?.origin?.lat,
                  selectedDriverTaskData?.order?.order_detail?.origin?.long,
                ]}
                zoom={13}
                style={{ height: '203px', width: '100%' }}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker
                  position={[
                    selectedDriverTaskData?.order?.order_detail?.origin?.lat,
                    selectedDriverTaskData?.order?.order_detail?.origin?.long,
                  ]}
                  icon={customIconBlue}
                >
                  <Popup>{selectedDriverTaskData?.order?.order_detail?.rental_delivery_location}</Popup>
                </Marker>
              </MapContainer>
            )}

          {(selectedDriverTaskData?.order?.order_type_id == 5 || selectedDriverTaskData?.order?.order_type_id == 6) &&
            selectedDriverTaskData?.order?.order_detail?.destination && (
              <MapContainer
                center={[
                  selectedDriverTaskData?.order?.order_detail?.destination?.lat,
                  selectedDriverTaskData?.order?.order_detail?.destination?.long,
                ]}
                zoom={13}
                style={{ height: '203px', width: '100%' }}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker
                  position={[
                    selectedDriverTaskData?.order?.order_detail?.destination?.lat,
                    selectedDriverTaskData?.order?.order_detail?.destination?.long,
                  ]}
                  icon={customIconYellow}
                >
                  <Popup>{selectedDriverTaskData?.order?.order_detail?.rental_return_location}</Popup>
                </Marker>
              </MapContainer>
            )}

          <InputField
            label={airportTransfer ? 'Detail Lokasi Penjemputan' : 'Detail Lokasi Pengantaran'}
            disable
            readOnly
            value={data?.order_detail?.rental_delivery_location_detail || '-'}
          />

          <InputField
            label={airportTransfer ? 'Detail Lokasi Pengantaran' : 'Detail Lokasi Pengembalian'}
            disable
            readOnly
            value={data?.order_detail?.rental_return_location_detail || '-'}
          />

          <div className="rent-period">
            <InputField
              label="Tanggal Sewa"
              disable
              readOnly
              value={
                data?.order_detail?.start_booking_date
                  ? format(new Date(data?.order_detail?.start_booking_date), 'dd MMMM yyyy', { locale: id })
                  : '-'
              }
            />
            <InputField
              label="Jam Mulai"
              disable
              readOnly
              value={
                data?.order_detail?.start_booking_date && data?.order_detail?.start_booking_time
                  ? format(
                      new Date(`${data?.order_detail?.start_booking_date} ${data?.order_detail?.start_booking_time}`),
                      'hh:mm aa',
                      { locale: id },
                    )
                  : '-'
              }
            />
          </div>

          <div className="rent-period">
            <InputField
              label="Tanggal Sewa"
              disable
              readOnly
              value={
                data?.order_detail?.end_booking_date
                  ? format(new Date(data?.order_detail?.end_booking_date), 'dd MMMM yyyy', { locale: id })
                  : '-'
              }
            />
            <InputField
              label="Jam Selesai"
              disable
              readOnly
              value={
                data?.order_detail?.end_booking_date && data?.order_detail?.end_booking_time
                  ? format(
                      new Date(`${data?.order_detail?.end_booking_date} ${data?.order_detail?.end_booking_time}`),
                      'hh:mm aa',
                      { locale: id },
                    )
                  : '-'
              }
            />
          </div>

          <InputField
            label="Tipe Paket Driver"
            disable
            readOnly
            value={
              vehiclePackages?.find((item) => item.id === selectedDriver?.vehicle?.vehicle_package_id)?.name || '-'
            }
            icon={<ChevronDown />}
            iconPosition="end"
          />

          <div className="identity-image-sim">
            <h3>Foto SIM</h3>
            <div className="image">
              <div
                className="image-wrapper"
                onClick={() => {
                  if (data?.is_admin_creation && data?.order_detail?.identity?.sim) {
                    return setShowImage({
                      show: true,
                      image: data?.order_detail.identity?.sim,
                    });
                  } else if (!data?.is_admin_creation && customer?.PersonalInfos?.sim) {
                    return setShowImage({
                      show: true,
                      image: `${IMAGE_BASE_URL}${customer?.PersonalInfos?.sim}`,
                    });
                  }
                }}
                style={{
                  cursor: data?.order_detail?.identity?.sim || customer?.PersonalInfos?.sim ? 'pointer' : 'not-allowed',
                }}
              >
                {data?.is_admin_creation && data?.order_detail?.identity?.sim ? (
                  <img src={data?.order_detail?.identity?.sim} alt="sim image" />
                ) : !data?.is_admin_creation && customer?.PersonalInfos?.sim ? (
                  <img src={`${IMAGE_BASE_URL}${customer?.PersonalInfos?.sim}`} alt="sim image" />
                ) : (
                  <div className="no-available">
                    <img src="/assets/image/no-image-available.png" className="img" />
                    <p>No Image Available</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="identity-image-ktp">
            <h3>Foto KTP</h3>
            <div className="image">
              <div
                className="image-wrapper"
                onClick={() => {
                  if (data?.is_admin_creation && data?.order_detail?.identity?.ktp) {
                    return setShowImage({
                      show: true,
                      image: data?.order_detail.identity?.ktp,
                    });
                  } else if (!data?.is_admin_creation && customer?.PersonalInfos?.ktp) {
                    return setShowImage({
                      show: true,
                      image: `${IMAGE_BASE_URL}${customer?.PersonalInfos?.ktp}`,
                    });
                  }
                }}
                style={{
                  cursor: data?.order_detail?.identity?.ktp || customer?.PersonalInfos?.ktp ? 'pointer' : 'not-allowed',
                }}
              >
                {data?.is_admin_creation && data?.order_detail?.identity?.ktp ? (
                  <img src={data?.order_detail?.identity?.ktp} alt="sim image" />
                ) : !data?.is_admin_creation && customer?.PersonalInfos?.ktp ? (
                  <img src={`${IMAGE_BASE_URL}${customer?.PersonalInfos?.ktp}`} alt="sim image" />
                ) : (
                  <div className="no-available">
                    <img src="/assets/image/no-image-available.png" className="img" />
                    <p>No Image Available</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {selectedDriverTaskData?.status !== 'OPEN' && (
          <>
            <h2>Detail Driver</h2>
            <div className="detail-driver-task__driver-detail">
              <InputField
                label="Fee Per-Task"
                disable
                readOnly
                value={
                  selectedDriverTaskData?.fee_per_task
                    ? gotRightCurrency(selectedDriverTaskData?.fee_per_task, data?.currency)
                    : '-'
                }
              />

              <InputField label="ID Driver" disable readOnly value={selectedDriverTaskData?.driver_id || '-'} />
            </div>
          </>
        )}
      </TableWrapper>

      <div className="detail-driver-task__vehicle-photos-task">
        {data?.order_type_id !== 5 && data?.order_type_id !== 6 ? (
          <>
            <VehiclePhotos
              title="Ambil dari Garasi"
              photoData={mappingPhotoData(selectedDriverTaskData?.progress, 'RUNNING')}
              setShowImage={setShowImage}
            />
            <VehiclePhotos
              title="Parkir ke Garasi"
              photoData={mappingPhotoData(selectedDriverTaskData?.progress, 'FINISH')}
              setShowImage={setShowImage}
            />
          </>
        ) : (
          <>
            <VehiclePhotos
              title="Pesanan Diambil"
              photoData={mappingPhotoData(selectedDriverTaskData?.progress, 'RUNNING')}
              setShowImage={setShowImage}
            />
            <VehiclePhotos
              title="Penjemputan"
              photoData={mappingPhotoData(selectedDriverTaskData?.progress, 'ON_PICK_UP_LOCATION')}
              setShowImage={setShowImage}
            />
            <VehiclePhotos
              title="Pengantaran"
              photoData={mappingPhotoData(selectedDriverTaskData?.progress, 'TRANSPORTING_PASSENGER')}
              setShowImage={setShowImage}
            />
            <VehiclePhotos
              title="Selesai Antar"
              photoData={mappingPhotoData(selectedDriverTaskData?.progress, 'FINISH')}
              setShowImage={setShowImage}
            />
          </>
        )}
      </div>
    </>
  );
};

export default WithoutDriverTask;
