import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as DriverIcon } from 'icons/driving-wheel-icon.svg';
import { Button, InputField, PaginationTable, SelectField, TableWrapper } from 'components/Global';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import SelectFieldImage from 'components/Global/SelectFieldImage';
import { format, parseISO } from 'date-fns';
import { uploadFile } from 'features/orders/actions';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import {
  createNewDriver,
  editDriver,
  getDetailDriver,
  getDriverTransactionHistory,
  getVehiclePackages,
  switchDriverStatus,
} from 'features/drivers/actions';
import { checkEmptyObject, checkPermission, setCurrency } from 'utils/functionality';
import SelectFieldBanks from 'components/Global/SelectFieldBanks';
import { getAllBanks, getAllCurrencies } from 'features/banks/actions';
import SelectFieldShuttle from 'components/Global/SelectFieldShuttle';
import { getAllRentalLocation } from 'features/rental-location/actions';

const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const UserStatus = ({ isUserBlocked, blockUserHandler }) => {
  return (
    <div className="status-info">
      <h3>{isUserBlocked ? 'Non Aktif' : 'Aktif'}</h3>
      <div className="status-info__toggle-wrapper">
        <div className="user__toggle-status">
          <label className="switch">
            <input type="checkbox" onChange={blockUserHandler} role={'switch'} checked={!isUserBlocked} />
            <span className="slider"></span>
          </label>
        </div>
      </div>
    </div>
  );
};

const AddNewDriver = () => {
  const { driverId } = useParams();
  const { showToast } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isUserBlocked, setIsUserBlocked] = useState(false);
  const [mappedCurrencies, setMappedCurrencies] = useState([]);
  const [data, setData] = useState({
    fullname: '',
    email: '',
    phone: '',
    date_of_birth: '',
    photo_ktp: '',
    photo_license: '',
    selfie: '',
    letter_of_agreement: '',
    bank_name: '',
    bank_account_number: '',
    currency: '',
    location_name: '',
    location_id: '',
    domicile: '',
    vehicle_name: '',
    vehicle_number_plate: '',
    vehicle_package_id: '',
  });
  const driverData = useSelector((state) => state.drivers.selected);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);
  const { data: dataBanks } = useSelector((state) => state.banks);
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);
  const { data: dataCurrencies } = useSelector((state) => state.currency);
  const { history } = useSelector((state) => state.drivers);
  const { vehiclePackages } = useSelector((state) => state.drivers);
  const [searchParams] = useSearchParams();
  const isEdit = searchParams.get('is_edit');

  const backHandler = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    if (data.fullname.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Mengisi Nama' });
    } else if (data.email.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Mengisi Email' });
    } else if (!new RegExp(regexEmail).test(data.email)) {
      return showToast({ type: 'error', message: 'Format Email Tidak Valid' });
    } else if (data.phone.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Mengisi No. Handphone' });
    } else if (data.date_of_birth.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Mengisi Tanggal Lahir' });
    } else if (data.domicile.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Mengisi Domisili Driver' });
    } else if (data.location_name.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Memilih Lokasi Driver' });
    } else if (data.currency.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Memilih Currency' });
    } else if (data.bank_account_number.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Mengisi Nomor Rekening' });
    } else if (data.bank_name.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Memilih Bank' });
    } else if (data.photo_ktp.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Memilih File KTP' });
    } else if (data.photo_license.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Memilih File SIM' });
    } else if (data.selfie.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Memilih File Selfie' });
    } else if (data.letter_of_agreement.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Memilih File Surat Perjanjian' });
    } else if (data.vehicle_name.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Mengisi Nama Mobil' });
    } else if (data.vehicle_number_plate.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Mengisi Nomor Polisi' });
    }
    // else if (data.vehicle_package_id === '') {
    //   return showToast({ type: 'error', message: 'Harap Memilih Tipe Paket Driver' });
    // }

    if (data.currency && !mappedCurrencies?.find((item) => item.name === data.currency)) {
      return showToast({ type: 'error', message: 'Harap Memilih Currency Yang Tersedia' });
    } else if (data.bank_name && !dataBanks?.data?.find((item) => item.name === data.bank_name)) {
      return showToast({ type: 'error', message: 'Harap Memilih Bank Yang Tersedia' });
    }

    delete data.vehicle_package_id;

    try {
      if (isEdit) {
        await dispatch(
          editDriver({
            payload: { ...data },
            id: driverId,
          }),
        ).unwrap();
        showToast({ type: 'success', message: 'Berhasil Merubah Data Akun Driver' });
      } else {
        await dispatch(
          createNewDriver({
            ...data,
            registration_type: 'email',
          }),
        ).unwrap();
        showToast({ type: 'success', message: 'Berhasil Membuat Akun Driver' });
      }
      navigate(-1);
    } catch (error) {
      if (isEdit) {
        showToast({ type: 'error', message: 'Gagal Merubah Data Akun Driver' });
        return;
      }
      showToast({ type: 'error', message: 'Gagal Membuat Akun Driver' });
    }
  };

  const onChangeImage = async (e, imageVar) => {
    if (!e.target.files.length) return;

    const file = e.target.files[0];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (file.size > maxSize) {
      showToast({ type: 'error', message: 'File size must be less than 2MB' });
      return;
    }

    try {
      const uploadImage = await dispatch(uploadFile({ file: e.target.files[0], name: imageVar })).unwrap();
      setData((prev) => ({ ...prev, [imageVar]: uploadImage[imageVar] }));
    } catch (error) {
      showToast({ type: 'error', message: 'Gagal Melakukan Upload File' });
    }
  };

  const onRemoveImage = (imageVar) => {
    setData((prev) => ({ ...prev, [imageVar]: '' }));
  };

  const blockUserHandler = async () => {
    try {
      await dispatch(switchDriverStatus(driverId)).unwrap();
      showToast({ type: 'success', message: 'Berhasil Melakukan Aksi' });
      dispatch(getDetailDriver(driverId));
    } catch (error) {
      showToast({ type: 'error', message: 'Gagal Melakukan Aksi: ' + error.message });
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const gotFeeCurrency = (fee, currency) => {
    const RightFee =
      fee && currency === 'IDR'
        ? setCurrency(fee).replace('Rp', `${currency}`)
        : fee && currency === 'SGD'
        ? `${currency} ${fee.toFixed(2)}`
        : fee === 0
        ? `${currency} 0`
        : '-';
    return RightFee;
  };

  useEffect(() => {
    if (!driverId) return;
    dispatch(getDetailDriver(driverId));
  }, [driverId]);

  useEffect(() => {
    if (!driverId) return;
    dispatch(getDriverTransactionHistory({ id: driverId, limit: 10, page: currentPage }));
  }, [driverId, currentPage]);

  useEffect(() => {
    dispatch(getAllBanks());
    dispatch(getAllRentalLocation());
    dispatch(getAllCurrencies());
  }, []);

  useEffect(() => {
    if (!data.location_id) return;

    dispatch(getVehiclePackages(data.location_id));
  }, [data.location_id]);

  useEffect(() => {
    if (checkEmptyObject(dataCurrencies)) return;
    setMappedCurrencies(() =>
      dataCurrencies.data
        // ?.filter((item) => item.is_enable)
        ?.map((item) => ({
          ...item,
          name: item.symbol,
          value: item.symbol.toLowerCase(),
        })),
    );
  }, [dataCurrencies]);

  useEffect(() => {
    if (checkEmptyObject(driverData)) return;
    setData({
      fullname: driverData.name || '',
      email: driverData.email || '',
      phone: driverData.phone || '',
      date_of_birth: driverData.PersonalInfos?.date_of_birth || '',
      photo_ktp: driverData.PersonalInfos?.ktp || '',
      photo_license: driverData.PersonalInfos?.sim || '',
      selfie: driverData.PersonalInfos?.selfie || '',
      letter_of_agreement: driverData.PersonalInfos?.letter_of_agreement || '',
      bank_account_number: driverData.account_bank?.no_rek || '',
      bank_name: driverData.account_bank?.nama_bank || '',
      currency: driverData.user_location?.currency || '',
      location_name: driverData.user_location?.location_name || '',
      location_id: driverData.user_location?.location_id || '',
      domicile: driverData?.PersonalInfos?.domicile || '',
      vehicle_name: driverData?.vehicle?.name || '',
      vehicle_number_plate: driverData?.vehicle?.license_number || '',
      vehicle_package_id: driverData?.vehicle?.vehicle_package_id || '',
    });
    setSelectedDate(() =>
      driverData.PersonalInfos?.date_of_birth ? parseISO(driverData.PersonalInfos?.date_of_birth) : '',
    );
    setIsUserBlocked(() => driverData.is_deactivated);
  }, [driverData]);

  return (
    <div className="driver">
      <header className="driver__header" onClick={backHandler}>
        <LeftArrow />
        <p>Kembali</p>
      </header>
      <TableWrapper
        icon={<DriverIcon fill="#009EF7" width="25px" height="25px" />}
        title="Driver"
        CustomFilterComponent={
          driverId ? <UserStatus isUserBlocked={isUserBlocked} blockUserHandler={blockUserHandler} /> : null
        }
      >
        <div className="driver__wrapper">
          <h1 className="driver__title">{driverId ? (isEdit ? 'Edit Driver' : 'Detail Driver') : 'Tambah Driver'}</h1>
          <div className="driver__input-group">
            <InputField
              label="Nama"
              disable={driverId && !isEdit}
              value={data.fullname}
              onChange={(e) => setData((prev) => ({ ...prev, fullname: e.target.value }))}
              placeholder="Masukan Nama"
              className="input-field"
            />
            <InputField
              label="Email"
              disable={driverId}
              value={data.email}
              onChange={(e) => setData((prev) => ({ ...prev, email: e.target.value }))}
              placeholder="Masukan Email"
              className="input-field"
            />
            <InputField
              label="No. Handphone"
              disable={driverId && !isEdit}
              type="number"
              value={data.phone}
              onChange={(e) => setData((prev) => ({ ...prev, phone: e.target.value }))}
              placeholder="Masukan No. Handphone"
              className="input-field"
              onWheel={numberInputOnWheelPreventChange}
            />
            <SelectFieldDate
              label="Tanggal Lahir"
              disable={driverId && !isEdit}
              htmlFor="tanggal-lahir"
              name="tanggal-lahir"
              defaultMonth={selectedDate}
              handleDaySelect={(date) => {
                if (date) {
                  setSelectedDate(date);
                  setData((prev) => ({
                    ...prev,
                    date_of_birth: format(date, 'yyyy-MM-dd').toString(),
                  }));
                }
              }}
              value={selectedDate ? format(selectedDate, 'dd/MM/yyyy') : ''}
              selectedDay={selectedDate}
              placeholder="Pilih Tanggal"
              forDateOfBirth
            />
            <InputField
              label="Domisili Driver"
              disable={driverId && !isEdit}
              value={data.domicile}
              onChange={(e) => setData((prev) => ({ ...prev, domicile: e.target.value }))}
              placeholder="Tulis Domisili Driver"
              className="input-field"
            />
            <SelectFieldShuttle
              label="Lokasi"
              htmlFor="lokasi"
              value={data.location_id}
              data={rentalLocationData}
              onChange={(e) =>
                setData((prev) => ({ ...prev, location_id: e.id, location_name: e.name, currency: e.currency }))
              }
              disable={driverId && !isEdit}
              placeholder="Pilih Lokasi"
              searchFeature
            />
            {driverId && <InputField label="Driver ID" disable value={driverData?.id} className="input-field" />}
            {/* <SelectFieldBanks
              label="Currency"
              htmlFor="currency"
              placeholder="Pilih Currency"
              disable
              data={mappedCurrencies || []}
              value={data?.currency}
            /> */}
            {driverId && <div />}
            <SelectFieldImage
              label="KTP"
              disabled={driverId && !isEdit}
              value={data.photo_ktp}
              onChange={(e) => onChangeImage(e, 'photo_ktp')}
              onRemove={() => onRemoveImage('photo_ktp')}
            />
            <SelectFieldImage
              label="SIM"
              disabled={driverId && !isEdit}
              value={data.photo_license}
              onChange={(e) => onChangeImage(e, 'photo_license')}
              onRemove={() => onRemoveImage('photo_license')}
            />
            <SelectFieldImage
              label="Foto Selfie"
              disabled={driverId && !isEdit}
              value={data.selfie}
              onChange={(e) => onChangeImage(e, 'selfie')}
              onRemove={() => onRemoveImage('selfie')}
            />
            <SelectFieldImage
              label="Surat Perjanjian"
              disabled={driverId && !isEdit}
              value={data.letter_of_agreement}
              onChange={(e) => onChangeImage(e, 'letter_of_agreement')}
              onRemove={() => onRemoveImage('letter_of_agreement')}
            />
            <InputField
              label="Nomor Rekening"
              disable={driverId && !isEdit}
              type="number"
              value={data.bank_account_number}
              onChange={(e) => setData((prev) => ({ ...prev, bank_account_number: e.target.value }))}
              placeholder="Masukan Nomor Rekening"
              className="input-field"
              onWheel={numberInputOnWheelPreventChange}
            />
            <SelectFieldBanks
              className="input-bank"
              htmlFor="metode-pembayaran-bank"
              label="Nama Bank"
              placeholder="Cari Bank..."
              data={dataBanks?.data || []}
              value={data.bank_name}
              disable={driverId && !isEdit}
              onChange={(e) => setData((prev) => ({ ...prev, bank_name: e.target.value }))}
              onClick={(e) => setData((prev) => ({ ...prev, bank_name: e }))}
            />
            <InputField
              label="Nama Mobil"
              disable={driverId && !isEdit}
              value={data.vehicle_name}
              onChange={(e) => setData((prev) => ({ ...prev, vehicle_name: e.target.value }))}
              placeholder="Masukan Nama Mobil"
              className="input-field"
            />
            <InputField
              label="Nomor Polisi Mobil"
              disable={driverId && !isEdit}
              value={data.vehicle_number_plate}
              onChange={(e) => setData((prev) => ({ ...prev, vehicle_number_plate: e.target.value }))}
              placeholder="Masukan Nomor Polisi Mobil"
              className="input-field"
            />
            <SelectField
              label="Tipe Paket Driver"
              type="text"
              disable={true}
              data={vehiclePackages}
              name="vehicle_package"
              value={data.vehicle_package_id}
              htmlFor="vehicle_package"
              placeholder="Pilih Paket Driver"
              onChange={(e) => setData((prev) => ({ ...prev, vehicle_package_id: parseInt(e.target.value) }))}
            />
          </div>

          {!driverId || isEdit ? (
            <div className="driver__buttons">
              <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
                Kembali
              </Button>

              {(!driverId || (isEdit && checkPermission(offCanvasMenu, currentMenu, 'update'))) && (
                <Button size="sm" className="button" width={208} onClick={handleSubmit}>
                  Simpan
                </Button>
              )}
            </div>
          ) : null}
        </div>
      </TableWrapper>
      {driverId && !isEdit ? (
        <TableWrapper title="Detail Saldo" style={{ marginTop: 22 }}>
          <div className="driver__balance-info">
            <p style={{ fontSize: 12, marginBottom: 6 }}>Saldo Driver</p>
            <h3>{gotFeeCurrency(history?.available_fee_amount, driverData.user_location?.currency)}</h3>
          </div>
          <div className="driver__transaction-history">
            <h5>History Transaksi</h5>
            {history?.data?.length > 0 ? (
              history.data.map((item, idx) => (
                <div className="driver__transaction-history__item" key={idx}>
                  <div>
                    <p style={{ fontWeight: 'bold', marginBottom: 6 }}>{item.description}</p>
                    <p style={{ fontSize: 12, color: '#A8A8A8' }}>
                      {format(parseISO(item.date), 'dd MMMM yyyy HH:mm:ss')}
                    </p>
                  </div>
                  <p
                    style={{
                      fontWeight: 'bold',
                      color: item.amount > 0 ? '#299B0A' : '#EA2626',
                    }}
                  >
                    {item.amount > 0
                      ? `+ ${gotFeeCurrency(item.amount, driverData.user_location?.currency).replace('+', '')}`
                      : `- ${gotFeeCurrency(item.amount, driverData.user_location?.currency).replace('-', '')}`}
                  </p>
                </div>
              ))
            ) : (
              <div className="driver__transaction-history__empty">
                <p>Belum Ada Data Transaksi</p>
              </div>
            )}
          </div>
          <PaginationTable
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            totalCount={history?.pagination?.total}
          />
        </TableWrapper>
      ) : null}
    </div>
  );
};

export default AddNewDriver;
