import React from 'react';
import { useAppContext } from 'components/Context/AppContext';
import { Button, InputField } from 'components/Global';
import { ReactComponent as ReuploadIcon } from 'icons/reupload-icon.svg';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

const DetailPenyewa = ({ data, customer, selectedCountry, getFileName, imagePreviewHandler, approvalUpdate }) => {
  const { setShowReviewIdentityModal } = useAppContext();

  return (
    <div className="detail-order-with-driver__renter-detail">
      <div className="detail-order-with-driver__renter-detail-title">
        <h1>Detail Penyewa</h1>
      </div>
      <div className="detail-order-with-driver__renter-detail-body">
        <InputField label="Nama Lengkap" disabled value={data?.user_name || '-'} />
        <InputField label="Email" disabled value={data?.email || '-'} />

        <div className="detail-order-with-driver__renter-detail-body__phone-number">
          <InputField
            label="No Handphone"
            disabled
            value={data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code || '-'}
            icon={
              <img
                src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                width={26}
                height={16}
                alt={selectedCountry?.name + ' nation flag'}
              />
            }
          />
          <InputField value={data?.phone_number || '-'} disabled />
        </div>

        <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

        <div className="detail-order-with-driver__renter-detail-body__phone-number">
          <div className="detail-order-with-driver__renter-detail-body__phone-number-wrapper">
            <InputField
              label="Whatsapp"
              disabled
              value={data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code || '-'}
              icon={
                <img
                  src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                  width={26}
                  height={16}
                  alt={selectedCountry?.name + ' nation flag'}
                />
              }
            />
            <div className="detail-order-with-driver__renter-detail-body__image-wrapper"></div>
          </div>
          <InputField disabled value={data?.wa_number || '-'} />
        </div>

        <div />

        <div className="detail-order__payment-detail-body__preview-image">
          <InputField
            label="KTP"
            disabled
            value={
              data?.is_admin_creation && getFileName(data?.order_detail?.identity?.ktp) !== ''
                ? getFileName(data?.order_detail?.identity?.ktp)
                : customer?.PersonalInfos?.ktp
                ? getFileName(customer?.PersonalInfos?.ktp)
                : 'Belum Upload KTP'
            }
            className="preview-image-input"
          />
          {(approvalUpdate || (!approvalUpdate && data?.order_status !== 'PAID')) &&
          data?.order_status !== 'REVIEWING_IDENTITY' &&
          ((data?.is_admin_creation && getFileName(data?.order_detail?.identity?.ktp) !== '') ||
            customer?.PersonalInfos?.ktp) ? (
            <Button
              bgColor="#D9D9D9"
              textColor="#000000"
              className="preview-image-btn"
              onClick={() =>
                imagePreviewHandler(
                  data?.is_admin_creation
                    ? data?.order_detail?.identity?.ktp
                    : IMAGE_BASE_URL + customer?.PersonalInfos?.ktp,
                )
              }
            >
              Lihat
            </Button>
          ) : null}
          {(data?.order_status === 'PAID' || data?.order_status === 'REVIEWING_IDENTITY') && !approvalUpdate && (
            <Button
              type="button"
              className="validation-identity-btn"
              onClick={() => setShowReviewIdentityModal({ type: 'ktp', show: true })}
            >
              Tinjau
            </Button>
          )}
          {(data?.order_status === 'PAID' || data?.order_status === 'REVIEWING_IDENTITY') &&
            customer?.PersonalInfos?.need_review_ktp && (
              <div className="reupload-icon">
                <ReuploadIcon />
                <p>Upload ulang KTP</p>
              </div>
            )}
        </div>

        {/* <div className="detail-order__payment-detail-body__preview-image">
          <InputField
            label="SIM"
            disabled
            value={
              data?.is_admin_creation && getFileName(data?.order_detail?.identity?.sim) !== ''
                ? getFileName(data?.order_detail?.identity?.sim)
                : customer?.PersonalInfos?.sim
                ? getFileName(customer?.PersonalInfos?.sim)
                : 'Belum Upload SIM'
            }
            className="preview-image-input"
          />
          {(approvalUpdate || (!approvalUpdate && data?.order_status !== 'PAID')) &&
          data?.order_status !== 'REVIEWING_IDENTITY' &&
          ((data?.is_admin_creation && getFileName(data?.order_detail?.identity?.sim) !== '') ||
            customer?.PersonalInfos?.sim) ? (
            <Button
              bgColor="#D9D9D9"
              textColor="#000000"
              className="preview-image-btn"
              onClick={() =>
                imagePreviewHandler(
                  data?.is_admin_creation
                    ? data?.order_detail?.identity?.sim
                    : IMAGE_BASE_URL + customer?.PersonalInfos?.sim,
                )
              }
            >
              Lihat
            </Button>
          ) : null}
          {(data?.order_status === 'PAID' || data?.order_status === 'REVIEWING_IDENTITY') && !approvalUpdate && (
            <Button
              type="button"
              className="validation-identity-btn"
              onClick={() => setShowReviewIdentityModal({ type: 'sim', show: true })}
            >
              Tinjau
            </Button>
          )}
          {(data?.order_status === 'PAID' || data?.order_status === 'REVIEWING_IDENTITY') &&
            customer?.PersonalInfos?.need_review_sim && (
              <div className="reupload-icon">
                <ReuploadIcon />
                <p>Upload ulang SIM</p>
              </div>
            )}
        </div> */}
      </div>
    </div>
  );
};

export default DetailPenyewa;
