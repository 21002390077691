import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllDrivers = createAsyncThunk('drivers/getAllDrivers', async (page = 1, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/driver-accounts?limit=10&page=${page}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const data = await response.data;
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const getDetailDriver = createAsyncThunk('drivers/getDetailDriver', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/driver-accounts/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const data = await response.data;
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const createNewDriver = createAsyncThunk('drivers/createNewDriver', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/admin/driver-accounts`,
      { ...payload },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    const data = await response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deleteDriverById = createAsyncThunk('drivers/deleteDriverById', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.delete(`${BASE_URL}/${API_VERSION}/admin/driver-accounts/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const switchDriverStatus = createAsyncThunk('drivers/switchDriverStatus', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.patch(
      `${BASE_URL}/${API_VERSION}/admin/driver-accounts/${id}/switch-deactivate`,
      {},
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const editDriver = createAsyncThunk('drivers/editDriver', async ({ payload, id }, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.put(
      `${BASE_URL}/${API_VERSION}/admin/driver-accounts/${id}`,
      { ...payload },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    const data = await response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getDriverTransactionHistory = createAsyncThunk(
  'drivers/getDriverTransactionHistory',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const { id, limit, page } = payload;

    let newUrl = `${BASE_URL}/${API_VERSION}/admin/transaction-histories/${id}/driver`;

    if (limit) {
      newUrl += `?limit=${limit}`;
    }

    if (page) {
      newUrl += `&page=${page}`;
    }

    try {
      const response = await axios.get(newUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      const data = await response.data;
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const getAllDriversByFilter = createAsyncThunk('drivers/getAllDriversByFilter', async (params, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { page, limit, search, locationId, isDeactivated, packageId } = params;

  let newUrl = `${BASE_URL}/${API_VERSION}/admin/driver-accounts?limit=${limit}&page=${page}`;

  if (search) {
    newUrl += `&search=${search}`;
  }

  if (locationId) {
    newUrl += `&location_id=${locationId}`;
  }

  if (typeof isDeactivated === 'boolean') {
    newUrl += `&is_deactivated=${isDeactivated}`;
  }

  if (packageId) {
    newUrl += `&vehicle_package_id=${packageId}`;
  }

  try {
    const response = await axios.get(newUrl, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const data = await response.data;
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const getAllDriverTasks = createAsyncThunk('drivers/getAllDriverTasks', async (params, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { page, limit, status, orderBy } = params;

  let newUrl = `${BASE_URL}/${API_VERSION}/admin/driver-tasks?page=${page}&limit=${limit}&order_seq=DESC`;

  if (status && status.length !== 0) {
    for (let statusChild of status) {
      newUrl += `&status=${statusChild}`;
    }
  }

  if (orderBy) {
    newUrl += `&order_by=${orderBy}`;
  }

  try {
    const response = await axios.get(newUrl, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const data = await response.data;
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const getDetailDriverTask = createAsyncThunk('drivers/getDetailDriverTask', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/driver-tasks/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const data = await response.data;
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const updateDriverTask = createAsyncThunk('drivers/updateDriverTask', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { id, ...payloadData } = payload;

  try {
    const response = await axios.patch(
      `${BASE_URL}/${API_VERSION}/admin/driver-tasks/${id}`,
      { ...payloadData },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    const data = await response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getDetailVehiclePackages = createAsyncThunk(
  'drivers/getDetailVehiclePackages',
  async (packageId, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/vehicle-packages/${packageId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      const data = await response.data;
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const getAllWithdraw = createAsyncThunk('drivers/getAllWithdraw', async (params, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { page, limit } = params;

  let newUrl = `${BASE_URL}/${API_VERSION}/admin/withdraws`;

  if (page) {
    if (newUrl.includes('?')) {
      newUrl += `&page=${page}`;
    } else {
      newUrl += `?page=${page}`;
    }
  }

  if (limit) {
    if (newUrl.includes('?')) {
      newUrl += `&limit=${limit}`;
    } else {
      newUrl += `?limit=${limit}`;
    }
  }

  try {
    const response = await axios.get(newUrl, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const data = await response.data;
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const getDetailWithdraw = createAsyncThunk('drivers/getDetailWithdraw', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/withdraw/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const data = await response.data;
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const getTransactionHistory = createAsyncThunk('drivers/getTransactionHistory', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { id, showType, refId, limit, page } = payload;

  let newUrl = `${BASE_URL}/${API_VERSION}/admin/transaction-histories/${id}/driver`;

  if (showType) {
    if (newUrl.includes('?')) {
      newUrl += `&show_type=${showType}`;
    } else {
      newUrl += `?show_type=${showType}`;
    }
  }

  if (refId) {
    if (newUrl.includes('?')) {
      newUrl += `&ref_id=${refId}`;
    } else {
      newUrl += `?ref_id=${refId}`;
    }
  }

  if (limit) {
    if (newUrl.includes('?')) {
      newUrl += `&limit=${limit}`;
    } else {
      newUrl += `?limit=${limit}`;
    }
  }

  if (page) {
    if (newUrl.includes('?')) {
      newUrl += `&page=${page}`;
    } else {
      newUrl += `?page=${page}`;
    }
  }

  try {
    const response = await axios.get(newUrl, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const data = await response.data;
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const approvalWithdraw = createAsyncThunk('drivers/approvalWithdraw', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { id, status, cancelReason, image } = payload;

  let payloadData;

  if (status === 'reject') {
    payloadData = { reason: cancelReason };
  } else if (status === 'process') {
    if (image) {
      payloadData = new FormData();
      payloadData.append('file', image);
    } else {
      payloadData = undefined;
    }
  } else {
    payloadData = undefined;
  }

  try {
    const response = await axios.post(`${BASE_URL}/${API_VERSION}/admin/withdraw/${id}/${status}`, payloadData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const uploadTransferProofWithdraw = createAsyncThunk(
  'drivers/uploadTransferProofWithdraw',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const { id, image } = payload;

    try {
      const response = await axios.post(
        `${BASE_URL}/${API_VERSION}/admin/withdraw/upload`,
        { file: image, id },
        {
          headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'multipart/form-data' },
        },
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getVehiclePackages = createAsyncThunk('drivers/getVehiclePackages', async (locationId, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(
      `${BASE_URL}/${API_VERSION}/admin/vehicle-packages?limit=10&page=1&location_id=${locationId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    const data = await response.data;
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});
